import request from '@/utils/request';

/**
 * @description 获取批次列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged 是否分页
 * @param {string} data.pageNum 页码
 * @param {string} data.pageSize 页长
 * @param {string} data.paramNameLike 模糊查询
 * @return {Array}
 */
export function apiGetBatchList(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchList',
    data,
  });
}

/**
 * @description 删除批次
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @return {*}
 */
export function apiDeleteBatch(data) {
  return request({
    method: 'post',
    url: '/worktable/delBatch',
    data,
  });
}

/**
 * @description 新建批次
 * @export
 * @param {object} params
 * @param {string} params.batchName 批次名称
 * @param {string} params.batchNo 批次编号
 * @param {string} params.includeUserId 负责人
 * @param {string} params.buyWay 采购方式
 * @param {string} params.location 开评标地点
 * @param {string} params.publishDate 招标文件发布日期
 * @param {string} params.signUpEndDate 报名截止日期
 * @param {string} params.startDate 开标日期
 * @param {string} params.businessType 业务类别
 * @param {string} params.groupId 组别
 * @return {*}
 */
export function apiCreateBatch(data) {
  return request({
    method: 'post',
    url: '/worktable/addBatch',
    data,
  });
}

/**
 * @description 获取项目列表
 * @export
 * @param {object} params
 * @param {string} params.isPaged 是否分页
 * @param {string} params.pageNum 页码
 * @param {string} params.pageSize 页长
 * @param {string} params.paramNameLike 模糊查询
 * @return {*}
 */
export function apiGetProjectList(params) {
  return request({
    method: 'get',
    url: '/worktable/getProjectList',
    params,
  });
}

/**
 * @description 删除项目
 * @export
 * @param {object} params
 * @param {string} params.projectId
 * @return {*}
 */
export function apiDeleteProject(params) {
  return request({
    method: 'get',
    url: '/worktable/delProject',
    params,
  });
}

/**
 * @description 新建项目空间
 * @export
 * @param {object} params
 * @param {string} params.projectNo 项目编号
 * @param {string} params.projectName 项目名称
 * @param {string} params.projectInfo 项目简介
 * @param {string} [params.modelId] 选用的模板id
 * @param {string} [params.includeUserId] 项目关系人
 * @param {string} params.projectType 项目类型
 * @param {string} params.projectAddress 项目建设地点
 * @param {string} params.projectBudget 项目预算（万元）
 * @param {string} params.projectStartDate 项目开工日期
 * @param {string} params.projectEndDate 项目竣工日期
 * @return {*}
 */
export function apiCreateProject(data) {
  return request({
    method: 'post',
    url: '/worktable/addProject',
    data,
  });
}

/**
 * @description 获取项目信息
 * @export
 * @param {object} params
 * @param {string} params.projectId
 * @return {*}
 */
export function apiGetProjectInfo(params) {
  return request({
    method: 'get',
    url: '/worktable/getProjectInfo',
    params,
  });
}

export function apiUpdateProjectInfo(data) {
  return request({
    method: 'post',
    url: '/worktable/updateProject',
    data,
  });
}

/**
 * @description 获取分包列表
 * @export
 * @param {object} params
 * @param {string} params.projectId 项目id
 * @param {string} params.isPaged 是否分页
 * @param {string} params.pageNum 页码
 * @param {string} params.pageSize 页长
 * @param {string} params.paramNameLike 模糊查询关键词
 * @return {*}
 */
export function apiGetPackageList(params) {
  return request({
    method: 'get',
    url: '/worktable/getPackageList',
    params,
  });
}

/**
 * @description 获取分包信息
 * @export
 * @param {object} params
 * @param {string} params.packageId
 * @return {*}
 */
export function apiGetPackageInfo(params) {
  return request({
    method: 'get',
    url: '/worktable/getPackageInfo',
    params,
  });
}

/**
 * @description 新增分包
 * @export
 * @param {object} data
 * @param {string} data.projectId
 * @param {string} data.packageName
 * @param {string} data.mainPerson
 * @param {string} data.packageInfo
 * @param {Array} data.includeUserId
 * @return {*}
 */
export function apiAddPackage(data) {
  return request({
    method: 'post',
    url: '/worktable/addPackage',
    data,
  });
}

/**
 * @description 删除分包
 * @export
 * @param {*} params
 * @param {*} params.packageId
 * @return {*}
 */
export function apiDeletePackage(params) {
  return request({
    method: 'get',
    url: '/worktable/delPackage',
    params,
  });
}

/**
 * @description 获取项目文件列表
 * @export
 * @param {object} params
 * @param {string} params.projectId 项目
 * @param {string} [params.packageId] 分包：不传查项目下所有，否则查分包
 * @param {string} params.isPaged 是否分页
 * @param {string} params.pageNum 页码
 * @param {string} params.pageSize 页长
 * @param {string} params.paramNameLike 模糊查询
 * @return {*}
 */
export function apiGetProjectFileList(params) {
  return request({
    method: 'get',
    url: '/worktable/getProjectFileList',
    params,
  });
}

/**
 * @description 删除项目文件
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiDelProjectFile(params) {
  return request({
    method: 'get',
    url: '/worktable/delProjectFile',
    params,
  });
}

/**
 * @description 修改项目文件属性
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileName 文件名称
 * @param {string} params.fileInfo 备注信息
 * @param {string} params.fileType 文件类型
 * @param {string} params.packageId 所属分包
 * @return {*}
 */
export function apiUpdateProjectFile(data) {
  return request({
    method: 'post',
    url: '/worktable/updateFileProperty',
    data,
  });
}

/**
 * @description 获取文件列表
 * @export
 * @return {Array}
 */
export function apiGetDocumentLVList() {
  return request({
    method: 'get',
    url: '/worktable/getDocumentList',
  });
}

export function apiGetProjectFileTypeLVList() {
  return request({
    method: 'get',
    url: '/worktable/getProjectFileTypeList',
  });
}

/**
 * @description 新增项目文件
 * @export
 * @param {object} data
 * @param {string} data.projectId
 * @param {string} data.fileName
 * @param {string} data.packageId
 * @param {string} data.fileInfo
 * @param {string} data.fileType
 * @param {string} data.fileUuid
 * @param {File} data.file
 * @return {*}
 */
export function apiAddProjectFile(data) {
  const { packageId, projectId, fileName, fileType, fileInfo, fileUuid, file } = data;
  const formdata = new FormData();
  if (file) {
    formdata.append('file', file);
  }
  if (fileName) {
    formdata.append('fileName', fileName);
  }
  if (fileUuid) {
    formdata.append('fileUuid', fileUuid);
  }
  if (projectId) {
    formdata.append('projectId', projectId);
  }
  if (packageId) {
    formdata.append('packageId', packageId);
  }
  if (fileType) {
    formdata.append('fileType', fileType);
  }
  if (fileInfo) {
    formdata.append('fileInfo', fileInfo);
  }
  return request({
    method: 'post',
    url: '/worktable/addProjectFile',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formdata,
  });
}

/**
 * @description 获取策划方案
 * @export
 * @return {*}
 */
export function apiGetPackagePlanList() {
  return request({
    method: 'get',
    url: '/worktable/getPackagePlan',
  });
}

/**
 * @description 获取用户列表
 * @export
 * @return {*}
 */
export function apiGetUserList() {
  return request({
    method: 'get',
    url: '/worktable/getUserList',
  });
}

/**
 * @description 项目策划创建分包
 * @export
 * @param {object} data
 * @param {string} data.projectId
 * @param {string} data.packagePlan
 * @return {*}
 */
export function apiCreatePackages(data) {
  return request({
    method: 'post',
    url: '/worktable/addPackageByPlan',
    data,
  });
}

/**
 * @description 获取收件箱文件清单
 * @export
 * @param {object} params
 * @param {string} params.projectId
 * @return {*}
 */
export function apiGetMailFileList(params) {
  return request({
    method: 'get',
    url: '/worktable/getInboxFileList',
    params,
  });
}

/**
 * @description 修改收件箱文件状态
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.statusId 1已投递/2已接收/3已忽略
 * @return {*}
 */
export function apiUpdateMailFile(params) {
  return request({
    method: 'get',
    url: '/worktable/updateInboxFile',
    params,
  });
}
