import request from '@/utils/request';

/**
 * @description 查询常用模板
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetCommonTemplateList(data) {
  return request({
    method: 'post',
    url: '/worktable/getCommonUseList',
    data,
  });
}

/**
 * @description 查询母版
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetOriginTemplateList(data) {
  return request({
    method: 'post',
    url: '/worktable/getLibraryList',
    data,
  });
}

/**
 * @description 文档库搜索
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetSearchTemplateList(data) {
  return request({
    method: 'post',
    url: '/worktable/searchLibrary',
    data,
  });
}

/**
 * @description 文库搜索热门关键词
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetLibSearchHotKeywords(data) {
  return request({
    method: 'post',
    url: '/worktable/getLibraryHotSearch',
    data,
  });
}

/**
 * @description 文库血缘关系视图
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetLibTreeView(data) {
  return request({
    method: 'post',
    url: '/worktable/getLibraryView',
    data,
  });
}
