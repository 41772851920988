import request from '@/utils/request';

/**
 * @description 获取版本快照，预览
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} [params.fileVersionId]
 * @return {*}
 */
export function apiGetFileEditorHtml(params) {
  return request({
    method: 'get',
    url: '/web/file/getVersionPhoto',
    params,
  });
}

/**
 * @description 下载word
 * @export
 * @param {object} data
 * @param {string} data.fileName
 * @param {string} data.html
 * @return {*}
 */
export function apiDownloadFile(data) {
  return request({
    method: 'post',
    url: '//47.98.211.72:16652/jofficeservice/getDocx',
    data,
    original: true,
    timeout: 0,
    responseType: 'blob',
  });
}
