/* eslint-disable max-len */
import request from '@/utils/request';

/**
 * @description 模板环形图
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetTemplatePieData(data) {
  return request({
    method: 'post',
    url: '/worktable/getModelRate',
    data,
  });
}

/**
 * @description 文件环形图
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetFilePieData(data) {
  return request({
    method: 'post',
    url: '/worktable/getFileRate',
    data,
  });
}

/**
 * @description 热力图
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetHeatMapData(data) {
  return request({
    method: 'post',
    url: '/worktable/getHeatMap',
    data,
  });
}

/**
 * @description 获取仪表盘数据
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetFileOverview(data) {
  return request({
    method: 'post',
    url: '/worktable/getMyFileStatistics',
    data,
  });
}

/**
 * @description 查询最近使用文档
 * @export
 * @param {object} params
 * @param {string} params.limit 查询条数
 * @return {*}
 */
export function apiGetRecentDocumentList(params) {
  return request({
    method: 'get',
    url: '/worktable/getRecentFileList',
    params,
  });
}

/**
 * @description 获取通知
 * @export
 * @param {object} data
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {'审核' | 'room_invite' | 'room_apply' | 'room_apply_result_accept' | 'room_apply_result_reject' | string} data.noticeType 通知类型：可多选，逗号分隔
 */
export function apiGetNoticeList(data) {
  return request({
    method: 'post',
    url: '/worktable/getResultNotice',
    data,
  });
}
