import { atom, selector } from 'recoil';
import { message } from 'antd';
import { apiGetCompanyList } from '@/service';

const profileRecoil = {
  params4companyList: atom({
    key: 'params4companyList',
    default: null,
  }),
  companyList: selector({
    key: 'companyList',
    get: async ({ get }) => {
      const { params4companyList } = profileRecoil;
      const params = get(params4companyList);
      if (!params) return null;
      const { data, err } = await apiGetCompanyList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
  }),
};

export default profileRecoil;
