import request from '@/utils/request';

/**
 * @description 查询批次信息
 * @export
 * @param {object} data
 * @param {*} data.batchId 批次id
 * @return {*}
 */
export function apiGetBatchInfo(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchInfo',
    data,
  });
}

/**
 * @description 添加批次人员
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.userId
 * @return {*}
 */
export function apiAddBatchUser(data) {
  return request({
    method: 'post',
    url: '/worktable/addBatchUser',
    data,
  });
}

/**
 * @description 获取批次人员
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.isPaged
 * @param {string} data.pageSize
 * @param {string} data.pageNum
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetBatchUserList(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchUserList',
    data,
  });
}

/**
 * @description 删除批次人员
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.userId
 * @return {*}
 */
export function apiDelBatchUser(data) {
  return request({
    method: 'post',
    url: '/worktable/delBatchUser',
    data,
  });
}

/**
 * @description 批次添加项目
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.projectStageName 项目标段名称
 * @param {string} data.buyType 采购类别
 * @param {string} data.mainPerson 负责人
 * @return {*}
 */
export function apiAddBatchProject(data) {
  return request({
    method: 'post',
    url: '/worktable/addBatchProject',
    data,
  });
}

/**
 * @description 批次删除项目
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.projectStageId
 * @return {*}
 */
export function apiDelBatchProject(data) {
  return request({
    method: 'post',
    url: '/worktable/delBatchProject',
    data,
  });
}

/**
 * @description 查询批次项目列表
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetBatchProjectList(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchProjectList',
    data,
  });
}

/**
 * @description 批次项目列表排序
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.projectStageList
 * @return {*}
 */
export function apiOrderBatchProjectList(data) {
  return request({
    method: 'post',
    url: '/worktable/orderBatchProjectList',
    data,
  });
}

/**
 * @description 批次项目详情
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.projectStageId
 * @return {*}
 */
export function apiGetBatchProjectInfo(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchProjectInfo',
    data,
  });
}

/**
 * @description 增加批次文件
 * @export
 * @param {FormData} data
 * @param {string} data.batchId
 * @param {string} data.fileName
 * @param {string} data.fileTypeId
 * @param {File} data.file
 * @return {*}
 */
export function apiAddBatchFile(data = {}) {
  const fmd = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value) {
      fmd.append(key, value);
    }
  });
  return request({
    method: 'post',
    url: '/worktable/addBatchFile',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: fmd,
  });
}

/**
 * @description 删除批次文件
 * @export
 * @param {*} data
 * @param {*} data.fileUuid
 * @return {*}
 */
export function apiDelBatchFile(data) {
  return request({
    method: 'post',
    url: '/worktable/delBatchFile',
    data,
  });
}

/**
 * @description 更改批次文件（移动）
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} [data.projectStageId] 标段id 不传移动到批次直属
 * @return {*}
 */
export function apiUpdateBatchFile(data) {
  return request({
    method: 'post',
    url: '/worktable/updateBatchFile',
    data,
  });
}

/**
 * @description 查询批次文件
 * @export
 * @param {object} data
 * @param {string} data.batchId 批次id
 * @param {string} data.projectStageId 批次下标段id
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @return {*}
 */
export function apiGetBatchFileList(data) {
  return request({
    method: 'post',
    url: '/worktable/getBatchFileList',
    data,
  });
}

/**
 * @description 修改批次项目属性
 * @export
 * @param {object} data
 * @param {object} data.batchId
 * @param {object} data.projectStageId
 * @param {string} data.label
 * @param {string} data.value
 * @return {*}
 */
export function apiUpdateBatchProjectAttr(data) {
  return request({
    method: 'post',
    url: '/worktable/updateBatchProperty',
    data,
  });
}

/**
 * @description 新增评标流程
 * @export
 * @param {object} data
 * @param {string} data.batchId
 * @param {string} data.judgeName
 * @param {string} data.projectStageId
 * @param {string} data.fileUuidList
 * @param {string} data.assessId
 * @return {*}
 */
export function apiCreatePBWorkflow(data) {
  return request({
    method: 'post',
    url: '/worktable/newJudge',
    data,
  });
}

/**
 * @description 删除评标流程
 * @export
 * @param {object} data
 * @param {string} data.judgeId
 * @return {*}
 */
export function apiDelPBWorkflow(data) {
  return request({
    method: 'post',
    url: '/worktable/delJudge',
    data,
  });
}

/**
 * @description 获取评标流程列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.batchId
 * @return {*}
 */
export function apiGetPBWorkflowList(data) {
  return request({
    method: 'post',
    url: '/worktable/getJudgeList',
    data,
  });
}

/**
 * @description 根据分包获取评审文件及评审模型
 * @export
 * @param {*} data
 * @param {*} data.projectStageId
 * @return {*}
 */
export function apiGetPBOptions(data) {
  return request({
    method: 'post',
    url: '/worktable/getFileAndAssess',
    data,
  });
}

/**
 * @description 获取评审流程信息
 * @export
 * @param {object} data
 * @param {string} data.judgeId
 * @return {*}
 */
export function apiGetPBWorkflowInfo(data) {
  return request({
    method: 'post',
    url: '/worktable/getJudgeInfo',
    data,
  });
}

/**
 * @description 获取评审流程的所有内容
 * @export
 * @param {object} data
 * @param {string} data.judgeId
 * @param {Array} data.fileUuidList
 * @return {*}
 */
export function apiGetPBDetail(data) {
  return request({
    method: 'post',
    url: '/worktable/getJudgeDetail',
    data,
  });
}
