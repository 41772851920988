import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import Icon from '@ant-design/icons';
import { ReactComponent as External } from '@/assets/images/external.svg';
import { Col, Row } from 'antd';
import helperDoc from '@/assets/helper.pdf';
import styles from './index.module.less';

const cx = classNames.bind(styles);

function Footer(props) {
  const { className } = props;
  return (
    <div className={cx('footer', className)}>
      <Row className={styles.inner} wrap>
        <Col className={styles.box} span={7}>
          <div className={styles.logo} />
          <div className={styles.co}>
            Copyright &copy; 2022 南京星恒数据科技有限公司
          </div>
        </Col>
        <Col className={styles.box} span={8} offset={4}>
          <h3>相关链接</h3>
          <ul>
            <li>
              <a href="http://www.nebulaedata.cn/" target="_blank" rel="noreferrer">
                星恒官网
                <Icon component={External} style={{ marginLeft: 4 }} />
              </a>
            </li>
            <li>
              <a href="http://www.sgcc.com.cn/" target="_blank" rel="noreferrer">
                国网物资公司
                <Icon component={External} style={{ marginLeft: 4 }} />
              </a>
            </li>
            <li>
              <Link to={helperDoc} target="_blank">
                帮助手册
                <Icon component={External} style={{ marginLeft: 4 }} />
              </Link>
            </li>
          </ul>
        </Col>
        <Col className={styles.box} span={5}>
          <h3>联系我们</h3>
          <ul>
            <li>邮箱：business@nebulaedata.cn</li>
            <li>电话：18952010388</li>
            <li>
              <div className={styles.wechat} />
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default memo(Footer);
