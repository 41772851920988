import request from '@/utils/request';

/**
 * @description 提交创建招标文件
 * @export
 * @param {object} data
 * @param {string} data.projectName 项目名称
 * @param {string} data.zbrCompanyName 招标人
 * @param {string} data.jgCompanyName 招标代理机构
 * @param {string} data.fileUuid 文件id
 * @param {string} data.fileName 文件名
 * @param {array} data.outlineIdListMap 替换章节选择
 * @return {*}
 */
export function apiCreateBiddingDoc(data) {
  return request({
    method: 'post',
    url: '/worktable/newBidDocument',
    data,
  });
}

/**
 * @description 获取备选项
 * @export
 * @return {*}
 */
export function apiGetGuideLVList() {
  return request({
    method: 'get',
    url: '/worktable/getLabelInfoList',
  });
}

/**
 * @description 获取推荐模板
 * @export
 * @param {object} data
 * @param {array} data.fileLabelIds 标签
 * @param {string} [data.searchLike]
 * @return {*}
 */
export function apiGetRecommendDoc(data) {
  return request({
    method: 'post',
    url: '/worktable/getSimilarDoc',
    data,
  });
}

/**
 * @description 获取文档大纲
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetDocCatalog(params) {
  return request({
    method: 'get',
    url: '/worktable/getOutlineIdList',
    params,
  });
}

/**
 * @description 获取分组标签列表
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetGroupLabelList(params) {
  return request({
    method: 'get',
    url: '/worktable/getLabelInfoList',
    params,
  });
}

/**
 * @description 按流程创建文件
 * @export
 * @param {object} params
 * @param {string} params.fileUuid 文件uuid
 * @param {string} params.fileVersionId 文件版本
 * @param {string} params.fileName 文件名称
 * @param {string} params.fileTypeId 文件类型
 * @param {string} params.fileLabelIds 文件标签
 * @param {string} params.merge 组装合并文件
 * @return {*}
 */
export function apiCreateNewDocument(data) {
  return request({
    method: 'post',
    url: '/worktable/newDocument',
    data,
  });
}

/**
 * @description 模板下拉框列表
 * @export
 * @param {object} data
 * @param {string} [data.type] MB/WJ
 * @return {*}
 */
export function apiGetTemplateLVList(data) {
  return request({
    method: 'post',
    url: '/worktable/getModelList',
    data,
  });
}

/**
 * @description 模板上传
 * @export
 * @param {FormData} data
 * @param {string} data.file
 * @param {string} [data.fileName]
 * @param {string} data.fileTypeId
 * @param {Array} data.fileLabelIds
 * @return {*}
 */
export function apiTemplateUpload(data) {
  const formData = new FormData();
  if (data.file) {
    formData.append('file', data.file);
  }
  if (data.fileName) {
    formData.append('fileName', data.fileName);
  }
  if (data.fileTypeId) {
    formData.append('fileTypeId', data.fileTypeId);
  }
  if (data.fileLabelIds) {
    formData.append('fileLabelIds', data.fileLabelIds);
  }
  return request({
    method: 'post',
    url: '/web/editTool/modelFileUpload',
    data: formData,
  });
}

/**
 * @description 数据导入同步
 * @export
 * @param {FormData} data
 * @param {string} data.file
 * @param {string} data.fileName
 * @param {string} data.fileDesc
 * @param {Array} data.fileLabelIds
 * @return {*}
 */
export function apiTargetImport(data) {
  const formData = new FormData();
  if (data.file) {
    formData.append('file', data.file);
  }
  if (data.fileName) {
    formData.append('fileName', data.fileName);
  }
  if (data.fileDesc) {
    formData.append('fileDesc', data.fileDesc);
  }
  if (data.fileLabelIds) {
    formData.append('fileLabelIds', data.fileLabelIds);
  }
  return request({
    method: 'post',
    url: '/worktable/upLoadSyncData',
    data: formData,
  });
}
