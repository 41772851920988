import { atom, selector } from 'recoil';
import { message } from 'antd';
import {
  apiGetTemplatePieData,
  apiGetFilePieData,
  apiGetHeatMapData,
  apiGetFileOverview,
  apiGetRecentDocumentList,
  apiGetNoticeList,
} from '@/service';

const homeRecoil = {
  params4RecentDocList: atom({
    key: 'params4RecentDocList',
    default: null,
  }),
  params4NotificationList: atom({
    key: 'params4NotificationList',
    default: null,
  }),
  userInfo: atom({
    key: 'userInfo',
    default: {},
  }),
  userMenuList: atom({
    key: 'userMenuList',
    default: [],
  }),
  templateChartPie: selector({
    key: 'templateChartPie',
    get: async () => {
      const { data, err } = await apiGetTemplatePieData();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileChartPie: selector({
    key: 'fileChartPie',
    get: async () => {
      const { data, err } = await apiGetFilePieData();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  heatMapData: selector({
    key: 'heatMapData',
    get: async () => {
      const { data, err } = await apiGetHeatMapData();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileOverview: selector({
    key: 'fileOverview',
    get: async () => {
      const { data, err } = await apiGetFileOverview();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  recentDocList: selector({
    key: 'recentDocList',
    get: async ({ get }) => {
      const { params4RecentDocList } = homeRecoil;
      const params = get(params4RecentDocList);
      if (!params) return null;
      const { data, err } = await apiGetRecentDocumentList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  noticeList: selector({
    key: 'noticeList',
    async get({ get }) {
      const { params4NotificationList } = homeRecoil;
      const params = get(params4NotificationList);
      if (!params) return null;
      const { data, err } = await apiGetNoticeList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  enabledFuncList: atom({
    key: 'enabledFuncList',
    default: [],
  }),
};

export default homeRecoil;
