import request from '@/utils/request';

const baseURL = process.env.NODE_ENV === 'development' ? '/' : undefined;

/**
 * @description 开始执行文档检查任务/查看任务进行进度
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiDocCheckStart(data) {
  return request({
    baseURL,
    original: true,
    method: 'post',
    url: '/checkhf/startcheck',
    data,
  });
}

/**
 * @description 获取文档检查结果列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetDocCheckResultList(data) {
  return request({
    baseURL,
    method: 'post',
    url: '/checkhf/showresult',
    data,
  });
}
