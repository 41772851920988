import request from '@/utils/request';

/**
 * @description 获取文件夹列表
 * @export
 * @param {object} data
 * @param {string} [data.folderId] 文件夹id",(非必填)
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.isPaged
 * @param {string} [data.paramNameLike] 查询关键字
 * @param {string} [data.limit]
 * @return {*}
 */
export function apiGetDocProFolderList(data) {
  return request({
    method: 'post',
    url: '/web/editTool/getFolderList',
    data,
  });
}

/**
 * @description 创建新文件夹
 * @export
 * @param {object} data
 * @param {string} data.folderName
 * @param {string} [data.folderParentId]
 * @return {*}
 */
export function apiCreateDocProNewFolder(data) {
  return request({
    method: 'post',
    url: '/web/editTool/newFolder',
    data,
  });
}

/**
 * @description 获取文件夹信息
 * @export
 * @param {object} data
 * @param {string} data.folderId
 * @return {*}
 */
export function apiGetDocProFolderInfo(data) {
  return request({
    method: 'post',
    url: '/web/editTool/getFolderInfo',
    data,
  });
}

/**
 * @description 获取文件信息
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @return {*}
 */
export function apiGetDocProFolderFileInfo(data) {
  return request({
    method: 'post',
    url: '/web/editTool/getDocumentInfo',
    data,
  });
}

/**
 * @description 删除目标（可多个）
 * @export
 * @param {object} data
 * @param {Array} data.keyList
 * @return {*}
 */
export function apiDeleteDocProTargets(data) {
  return request({
    method: 'post',
    url: '/web/editTool/delFolder',
    data,
  });
}

/**
 * @description 重命名目标
 * @export
 * @param {object} data
 * @param {string} data.folderId
 * @param {string} data.folderName
 * @return {*}
 */
export function apiRenameDocProFolder(data) {
  return request({
    method: 'post',
    url: '/web/editTool/renameFolder',
    data,
  });
}

/**
 * @description 重命名文件
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileName
 * @return {*}
 */
export function apiRenameDocProFile(data) {
  return request({
    method: 'post',
    url: '/web/editTool/renameDocument',
    data,
  });
}

/**
 * @description 移动目标
 * @export
 * @param {object} data
 * @param {string} data.keyList
 * @param {string} data.folderId
 * @return {*}
 */
export function apiMoveDocProTargets(data) {
  return request({
    method: 'post',
    url: '/web/editTool/moveFolder',
    data,
  });
}

/**
 * @description 文件搜索
 * @export
 * @param {object} data
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.isPaged
 * @param {string} data.paramNameLike
 * @param {string} data.limit
 * @return {*}
 */
export function apiGetDocProFileSearch(data) {
  return request({
    method: 'post',
    url: '/web/editTool/search',
    data,
  });
}

/**
 * @description 获取移动文件夹列表，懒加载
 * @export
 * @param {object} data
 * @param {'file' | 'template' | 'origin'} data.type 文件类型：文件、模板、母版
 * @param {string} [data.folderId] 不传查root节点
 * @param {boolean} [data.all] 是否查整棵树
 */
export function apiGetDocProFolderTreeLazy(data) {
  return request({
    method: 'post',
    url: '/web/editTool/getMoveFolderList',
    data,
  });
}

/**
 * @description 文件上传
 * @export
 * @param {FormData} data
 * @param {string} data.file
 * @param {string} data.folderId
 */
export function apiFileUpload(data) {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('folderId', data.folderId || '');
  return request({
    method: 'post',
    url: '/worktable/uploadModelFile',
    data: formData,
  });
}
