import { atom, selector } from 'recoil';
import { message } from 'antd';
import { apiGetDocCheckResultList } from '@/service';

export const params4DocCheckResultList = atom({
  key: 'params4DocCheckResultList',
  default: null,
});

export const docCheckResultList = selector({
  key: 'docCheckResultList',
  get: async ({ get }) => {
    const defaultResult = [];
    const params = get(params4DocCheckResultList);
    if (!params) return defaultResult;
    const { data, err } = await apiGetDocCheckResultList(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    if (params.cb) params.cb(data);
    return data || defaultResult;
  },
});
