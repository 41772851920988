import { atom, selector } from 'recoil';
import { message } from 'antd';
import {
  apiGetDocumentList,
  apiGetStarList,
  apiGetAuxiliaryList,
  apiGetContributionList,
  apiGetMyDowloadList,
  apiGetParamsScreenshot,
} from '@/service';

const mySpaceRecoil = {
  params4documentList: atom({
    key: 'params4documentList',
    default: null,
  }),
  params4DocFileList: atom({
    key: 'params4DocFileList',
    default: null,
  }),
  params4DocTempList: atom({
    key: 'params4DocTempList',
    default: null,
  }),
  params4StarList: atom({
    key: 'params4StarList',
    default: null,
  }),
  params4AuxiliaryList: atom({
    key: 'params4AuxiliaryList',
    default: null,
  }),
  params4ContributionList: atom({
    key: 'params4ContributionList',
    default: null,
  }),
  params4DownloadList: atom({
    key: 'params4DownloadList',
    default: null,
  }),
  params4ParamsScreenshot: atom({
    key: 'params4ParamsScreenshot',
    default: null,
  }),
  documentList: selector({
    key: 'documentList',
    get: async ({ get }) => {
      const { params4documentList } = mySpaceRecoil;
      const params = get(params4documentList);
      if (!params) return null;
      const { data, err } = await apiGetDocumentList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
  }),
  myFileList: selector({
    key: 'myFileList',
    get: async ({ get }) => {
      const { params4DocFileList } = mySpaceRecoil;
      const params = get(params4DocFileList);
      if (!params) return null;
      const { data, err } = await apiGetDocumentList({ ...params, type: 'WJ' });
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total, ...params };
    },
  }),
  myTempList: selector({
    key: 'myTempList',
    get: async ({ get }) => {
      const { params4DocTempList } = mySpaceRecoil;
      const params = get(params4DocTempList);
      if (!params) return null;
      const { data, err } = await apiGetDocumentList({ ...params, type: 'MB' });
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total, ...params };
    },
  }),
  starList: selector({
    key: 'starList',
    get: async ({ get }) => {
      const { params4StarList } = mySpaceRecoil;
      const params = get(params4StarList);
      if (!params) return null;
      const { data, err } = await apiGetStarList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (!data) return { list, total };
      if (params.isPaged === '0') {
        list = data;
        total = data.length || total;
      } else {
        list = data.list || list;
        total = data.total || total;
      }
      return { list, total };
    },
  }),
  auxiliaryList: selector({
    key: 'auxiliaryList',
    get: async ({ get }) => {
      const params = get(mySpaceRecoil.params4AuxiliaryList);
      if (!params) return null;
      const { data, err } = await apiGetAuxiliaryList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  contributionList: selector({
    key: 'contributionList',
    get: async ({ get }) => {
      const params = get(mySpaceRecoil.params4ContributionList);
      if (!params) return null;
      const { data, err } = await apiGetContributionList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  downloadList: selector({
    key: 'downloadList',
    get: async ({ get }) => {
      const params = get(mySpaceRecoil.params4DownloadList);
      if (!params) return null;
      const { data, err } = await apiGetMyDowloadList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  paramsScreenshot: selector({
    key: 'paramsScreenshot',
    get: async ({ get }) => {
      const params = get(mySpaceRecoil.params4ParamsScreenshot);
      const defaultValue = [];
      if (!params) return defaultValue;
      const { data, err } = await apiGetParamsScreenshot(params);
      if (err) {
        message.error(err.message);
        return defaultValue;
      }
      return data ?? defaultValue;
    },
  }),
};

export default mySpaceRecoil;
