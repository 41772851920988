import { atom, selector } from 'recoil';
import { message } from 'antd';
import { apiGetFolderList, apiGetFolderInfo, apiGetFolderFileInfo } from '@/service';

const namespace = 'fileSpace';

export const params4folderList = atom({
  key: `${namespace}/params4folderList`,
  value: null,
});

export const params4folderInfo = atom({
  key: `${namespace}/params4folderInfo`,
  value: null,
});

export const params4fileInfo = atom({
  key: `${namespace}/params4fileInfo`,
  value: null,
});

export const params4breadcrumb = atom({
  key: `${namespace}/params4breadcrumb`,
  value: null,
});

export const folderList = selector({
  key: `${namespace}/folderList`,
  get: async ({ get }) => {
    const defaultResult = [];
    const params = get(params4folderList);
    if (!params) return defaultResult;
    const { data, err } = await apiGetFolderList(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    return data ?? defaultResult;
  },
});

export const folderInfo = selector({
  key: `${namespace}/folderInfo`,
  get: async ({ get }) => {
    const defaultResult = {};
    const params = get(params4folderInfo);
    if (!params) return defaultResult;
    const { data, err } = await apiGetFolderInfo(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    return data ?? defaultResult;
  },
});

export const fileInfo = selector({
  key: `${namespace}/fileInfo`,
  get: async ({ get }) => {
    const defaultResult = {};
    const params = get(params4fileInfo);
    if (!params) return defaultResult;
    const { data, err } = await apiGetFolderFileInfo(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    return data ?? defaultResult;
  },
});

export const breadcrumb = selector({
  key: `${namespace}/breadcrumb`,
  get: async ({ get }) => {
    const defaultResult = [];
    const params = get(params4breadcrumb);
    if (!params) return defaultResult;
    const { data, err } = await apiGetFolderInfo(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    return data?.folderPath?.reverse?.() ?? defaultResult;
  },
});
