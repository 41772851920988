import { atom, selector } from 'recoil';
import {
  apiGetBatchList,
  apiGetProjectList,
  apiGetProjectInfo,
  apiGetPackageList,
  apiGetPackageInfo,
  apiGetProjectFileList,
  apiGetDocumentLVList,
  apiGetPackagePlanList,
  apiGetUserList,
  apiGetProjectFileTypeLVList,
  apiGetMailFileList,
} from '@/service';
import { message } from 'antd';

const myProjectRecoil = {
  params4BatchList: atom({
    key: 'params4BatchList',
    default: null,
  }),
  params4ProjectList: atom({
    key: 'params4ProjectList',
    default: null,
  }),
  params4ProjectInfo: atom({
    key: 'params4ProjectInfo',
    default: null,
  }),
  params4PackageList: atom({
    key: 'params4PackageList',
    default: null,
  }),
  params4PackageInfo: atom({
    key: 'params4PackageInfo',
    default: null,
  }),
  params4FileList: atom({
    key: 'params4FileList',
    default: null,
  }),
  params4PackagePlanList: atom({
    key: 'params4PackagePlanList',
    default: null,
  }),
  params4UserLVList: atom({
    key: 'params4UserLVList',
    default: null,
  }),
  params4MailList: atom({
    key: 'params4MailList',
    default: null,
  }),
  batchList: selector({
    key: 'batchList',
    get: async ({ get }) => {
      const { params4BatchList } = myProjectRecoil;
      const params = get(params4BatchList);
      if (!params) return null;
      const { data, err } = await apiGetBatchList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  projectList: selector({
    key: 'projectList',
    get: async ({ get }) => {
      const { params4ProjectList } = myProjectRecoil;
      const params = get(params4ProjectList);
      if (!params) return null;
      const { data, err } = await apiGetProjectList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  projectInfo: selector({
    key: 'projectInfo',
    get: async ({ get }) => {
      const { params4ProjectInfo } = myProjectRecoil;
      const params = get(params4ProjectInfo);
      if (!params) return null;
      const { data, err } = await apiGetProjectInfo(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  packageList: selector({
    key: 'packageList',
    get: async ({ get }) => {
      const { params4PackageList } = myProjectRecoil;
      const params = get(params4PackageList);
      if (!params) return null;
      const { data, err } = await apiGetPackageList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  packageInfo: selector({
    key: 'packageInfo',
    get: async ({ get }) => {
      const { params4PackageInfo } = myProjectRecoil;
      const params = get(params4PackageInfo);
      if (!params) return null;
      const { data, err } = await apiGetPackageInfo(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileList: selector({
    key: 'fileList',
    get: async ({ get }) => {
      const { params4FileList } = myProjectRecoil;
      const params = get(params4FileList);
      if (!params) return null;
      const { data, err } = await apiGetProjectFileList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  documentLVList: selector({
    key: 'documentLVList',
    get: async () => {
      const { data, err } = await apiGetDocumentLVList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileTypeLVList: selector({
    key: 'fileTypeLVList',
    get: async () => {
      const { data, err } = await apiGetProjectFileTypeLVList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  packagePlanList: selector({
    key: 'packagePlanList',
    get: async ({ get }) => {
      const { params4PackagePlanList } = myProjectRecoil;
      const params = get(params4PackagePlanList);
      if (!params) return null;
      const { data, err } = await apiGetPackagePlanList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data?.planList;
    },
  }),
  userLvList: selector({
    key: 'userList',
    get: async ({ get }) => {
      const { params4UserLVList } = myProjectRecoil;
      const params = get(params4UserLVList);
      if (!params) return null;
      const { data, err } = await apiGetUserList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  mailList: selector({
    key: 'mailList',
    get: async ({ get }) => {
      const { params4MailList } = myProjectRecoil;
      const params = get(params4MailList);
      if (!params) return null;
      const { data, err } = await apiGetMailFileList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default myProjectRecoil;
