import axios from 'axios';
import CryptoJS from 'crypto-js';
import { stringify } from 'qs';
import { v4 as uuid } from 'uuid';
import { isObject } from 'lodash';
import { isFormData } from '.';

const baseURL = process.env.REACT_APP_BASE_URL;

const secretKey = process.env.REACT_APP_AES_KEY;

const defaultEncryption = process.env.REACT_APP_ENABLE_ENCRYPT === 'true'; // 默认是否开启加密

const pageId = uuid();

/** @type {import('axios').AxiosRequestConfig} */
const baseConfig = {
  baseURL,
  timeout: 25000,
  headers: {
    platform: 'HanFei-v2',
    pageId,
  },
};

export function encrypt(data) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const text = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  return CryptoJS.AES.encrypt(text, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decrypt(ciphertext) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  // 解密：如果未经过加密就解密，将直接返回空字符串，判断是否加密就判断解密后是否为空字符串
  const aes = CryptoJS.AES.decrypt(ciphertext, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const text = CryptoJS.enc.Utf8.stringify(aes);
  // NOTE: for debug: 注意，浏览器打印渲染出来后，会导致转义符被转义，所以看到的是转义后的结果，保持原始结果要加入到对象的属性中
  // console.log({ origin: ciphertext, decrypt: text });
  if (text) {
    // 已加密：返回解密后的数据
    return JSON.parse(text);
  }
  // 未加密：返回原始数据
  return ciphertext;
}

function errorHandler(error) {
  if (error?.code === 'ECONNABORTED') {
    return Promise.reject(
      new Error(`请求超时 (code: ECONNABORTED, ${error.message})`),
    );
  }
  if (error?.response?.status === 401) {
    // Unauthorized: redirect to login page
    const params = {
      origin: window.location.href,
    };
    window.location.href = `/login?${stringify(params)}`;
    return Promise.reject(new Error('请求要求用户进行身份认证(code: 401)'));
  }
  return Promise.reject(error);
}

// axios加密实例
const $axios = axios.create(baseConfig);

$axios.interceptors.request.use((config) => {
  const { method, data, headers } = config;
  if (method === 'post') {
    // 根据参数自动判断Content-Type, data
    let aes = encrypt(data);
    let contentType;
    if (isFormData(data)) {
      // form表单
      contentType = 'application/x-www-form-urlencoded';
      // 表单参数不加密，加密会损坏表单file对象
      aes = data;
    } else if (isObject(data)) {
      // json格式
      contentType = 'application/json';
    } else if (typeof data === 'string' || typeof data === 'number') {
      // 字符串格式
      contentType = 'text/plain';
    } else if (data === undefined || data === null) {
      // 无参数默认json格式
      contentType = 'application/json';
      aes = undefined;
    }
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    return {
      ...config,
      headers,
      data: aes,
    };
  }
  return config;
});

$axios.interceptors.response.use(
  (response) => ({
    ...response,
    data: decrypt(response.data),
  }),
  (error) => errorHandler(error),
);

// axios普通实例
const $axiosCommon = axios.create(baseConfig);

$axiosCommon.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error),
);

export default async function request(config) {
  const { original, encryption = defaultEncryption, ...requestConfig } = config;
  const _axios = encryption ? $axios : $axiosCommon;
  return _axios
    .request(requestConfig)
    .then(({ data }) => {
      // 原始格式
      if (original) {
        return data;
      }
      // 处理常用格式
      if (data.status === 0) {
        return data.data;
      }
      return Promise.reject(new Error(`${data?.msg}(code: ${data?.status})`));
    })
    .then((data) => ({ data }))
    .catch((err) => ({ err }));
}
