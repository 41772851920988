import request from '@/utils/request';

/**
 * @description 获取对比版本列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetDiffCompareOptions(data) {
  return request({
    method: 'post',
    url: '/web/file/getCompareVersionList',
    data,
  });
}

/**
 * @description 获取手动对比结果列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileUuid2
 * @return {*}
 */
export function apiGetDiffCompareResultList(data) {
  return request({
    method: 'post',
    url: '/web/file/handCompare',
    data,
  });
}
