import { atom, selector } from 'recoil';
import {
  apiGetHelperDocCatalog,
  apiGetHelperDocContent,
  apiGetHelperSearchList,
  apiGetHelperHotSearchList,
} from '@/service';
import { message } from 'antd';

const helperRecoil = {
  params4document: atom({
    key: 'params4document',
    default: null,
  }),
  params4searchList: atom({
    key: 'params4searchList',
    default: null,
  }),
  catalog: selector({
    key: 'catalog',
    get: async () => {
      const { data, err } = await apiGetHelperDocCatalog();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  keywords: selector({
    key: 'keywords',
    get: async () => {
      const { data, err } = await apiGetHelperHotSearchList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  document: selector({
    key: 'document',
    get: async ({ get }) => {
      const { params4document } = helperRecoil;
      const params = get(params4document);
      if (!params) return null;
      const { data, err } = await apiGetHelperDocContent(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  searchList: selector({
    key: 'searchList',
    get: async ({ get }) => {
      const { params4searchList } = helperRecoil;
      const params = get(params4searchList);
      if (!params) return null;
      const { data, err } = await apiGetHelperSearchList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default helperRecoil;
