import request from '@/utils/request';

/**
 * @description 获取我的文档
 * @export
 * @param {object} data
 * @param {string} data.isPaged 是否分页
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike 模糊查询关键字
 * @return {Array}
 */
export function apiGetDocumentList(data) {
  return request({
    method: 'post',
    url: '/worktable/getMyDocumentList',
    data,
  });
}

/**
 * @description 删除文档
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiDeleteDocument(params) {
  return request({
    method: 'get',
    url: '/worktable/delMyDocument',
    params,
  });
}

/**
 * @description 添加收藏
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.collectionType 1模板 2法律 3摘编 4词条
 * @return {*}
 */
export function apiAddStar(params) {
  return request({
    method: 'get',
    url: '/web/file/addCollection',
    params,
  });
}

/**
 * @description 取消收藏
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiDeleteStar(params) {
  return request({
    method: 'get',
    url: '/web/file/delCollection',
    params,
  });
}

/**
 * @description 获取我的收藏列表
 * @export
 * @param {object} params
 * @param {string} params.isPaged 是否分页
 * @param {string} params.pageNum 页码
 * @param {string} params.pageSize 页长
 * @param {string} params.paramNameLike 模糊匹配
 * @param {string} params.collectionType 收藏类型 1模板 3摘编 4词条
 * @return {Array}
 */
export function apiGetStarList(params) {
  return request({
    method: 'get',
    url: '/web/file/mycollection',
    params,
  });
}

/**
 * @description 获取文件的权限路径
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetAuthFileURL(params) {
  return request({
    method: 'get',
    url: '/web/file/getTrueVersionId',
    params,
  });
}

/**
 * @description 辅助工具新增表单
 * @export
 * @param {object} data
 * @param {string} data.formName 表单名称
 * @param {object} data.formValue 表单项的值
 * @param {string} data.toolId 工具分类id
 * @return {*}
 */
export function apiAddAuxiliaryForm(data) {
  return request({
    method: 'post',
    url: '/worktable/newSupToolForm',
    data,
  });
}

/**
 * @description 删除辅助工具表单
 * @export
 * @param {object} params
 * @param {string} params.formId
 * @return {*}
 */
export function apiDeleteAuxiliaryForm(params) {
  return request({
    method: 'get',
    url: '/worktable/delSupToolForm',
    params,
  });
}

/**
 * @description 修改辅助工具表单
 * @export
 * @param {object} data
 * @param {string} data.formId 表单id
 * @param {string} data.formName 表单名称
 * @param {object} data.formValue 表单项的值
 * @return {*}
 */
export function apiUpdateAuxiliaryForm(data) {
  return request({
    method: 'post',
    url: '/worktable/updateSupToolForm',
    data,
  });
}

/**
 * @description 查询辅助工具所有分类表单
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetAuxiliaryList(data) {
  return request({
    method: 'get',
    url: '/worktable/getSupToolFormList',
    data,
  });
}

/**
 * @description 我的贡献
 * @export
 * @param {object} data
 * @param {string} data.isPaged 是否分页
 * @param {string} data.pageNum 页码
 * @param {string} data.pageSize 每页条目数量
 * @param {string} data.paramNameLike 模糊查询关键词
 * @param {string} data.fileTypeId 文档类型
 * @param {string} data.fileLabelList [标签id的list]
 * @return {*}
 */
export function apiGetContributionList(data) {
  return request({
    method: 'post',
    url: '/worktable/getMyContribution',
    data,
  });
}

/**
 * @description 获取我的下载列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize 页长
 * @param {string} data.paramNameLike 模糊查询
 * @return {*}
 */
export function apiGetMyDowloadList(data) {
  return request({
    method: 'post',
    url: '/worktable/getMyDownloadList',
    data,
  });
}

/**
 * @description 删除我的下载
 * @export
 * @param {object} data
 * @param {string} data.uuid
 * @return {*}
 */
export function apiDelMyDowloadItem(data) {
  return request({
    method: 'post',
    url: '/worktable/delMyDownload',
    data,
  });
}

/**
 * @description 下载列表中下载
 * @export
 * @param {object} data
 * @param {string} data.uuid
 * @return {*}
 */
export function apiDownloadMyDowloadItem(data) {
  return request({
    method: 'post',
    url: '/worktable/downloadMyDownload',
    data,
    original: true,
    timeout: 0,
    responseType: 'blob',
  });
}

/**
 * @description 触发后台下载
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileName
 * @param {string} data.html
 * @return {*}
 */
export function apiDownloadTrigger(data) {
  return request({
    method: 'post',
    url: '/worktable/getDocx',
    data,
  });
}

/**
 * @description 获取参数清单快照
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetParamsScreenshot(params) {
  return request({
    method: 'get',
    url: '/web/file/getFileParamsWriteList',
    params,
  });
}
