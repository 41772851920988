import request from '@/utils/request';

/**
 * @description 获取所有功能列表
 * @export
 * @param {object} data
 * @param {boolean} [data.isUsed] 是否开通
 * @return {*}
 */
export function apiGetAppFunctionList(data) {
  return request({
    method: 'post',
    url: '/web/editTool/getApplicationList',
    data,
  });
}

/**
 * @description
 * @export
 * @param {object} data
 * @param {string} data.fileTypeId 功能uuid
 * @param {string} data.isUsed 是否开通
 * @return {*}
 */
export function apiChangeAppFunctionStatus(data) {
  return request({
    method: 'post',
    url: '/web/editTool/changeApplicationStatus',
    data,
  });
}
