import request from '@/utils/request';

/**
 * @description 模板审核列表
 * @export
 * @param {object} data
 * @param {string} data.isPaged
 * @param {string} data.pageNum
 * @param {string} data.pageSize
 * @param {string} data.paramNameLike
 * @param {string} data.type 'WJ' | 'MB'
 */
export function apiGetTemplateReviewList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAuditingFileList',
    data: {
      ...data,
      type: 'MB',
    },
  });
}

/**
   * @description 文件审核列表
   * @export
   * @param {object} data
   * @param {string} data.isPaged
   * @param {string} data.pageNum
   * @param {string} data.pageSize
   * @param {string} data.paramNameLike
   * @param {string} data.type 'WJ' | 'MB'
   */
export function apiGetFileReviewList(data) {
  return request({
    method: 'POST',
    url: '/worktable/getAuditingFileList',
    data: {
      ...data,
      type: 'WJ',
    },
  });
}

/**
   * @description 审批流程列表
   * @export
   */
export function apiGetReviewFlowList() {
  return request({
    method: 'POST',
    url: '/examine/getExamineList',
  });
}

/**
   * @description 修改审批流程列表
   * @export
   */
export function apiChgReviewFlow(data) {
  return request({
    method: 'POST',
    url: '/examine/chgExamineList',
    data,
  });
}

/**
 * @description 更新模板审核状态
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.auditingStatus 枚举值 通过/不通过
 * @param {string} data.auditingReason 审核意见
 */
export function apiChgTemplateReviewItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/changeAuditingFileStatus',
    data,
  });
}

/**
 * @description 更新文件审核状态
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.auditingStatus 枚举值 通过/不通过
 * @param {string} data.auditingReason 审核意见
 * @param {string} data.uuid 审核意见
 */
export function apiChgFileReviewItem(data) {
  return request({
    method: 'POST',
    url: '/worktable/changeAuditingFileStatus',
    data,
  });
}
