/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import { Result, Button, Typography, Collapse } from 'antd';
import {
  CloseCircleFilled,
  SendOutlined,
  CaretRightOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { apiDevTrigger } from '@/service';
import Cookies from 'js-cookie';
import styles from './index.module.less';

const { Paragraph, Text } = Typography;

const { Panel } = Collapse;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorList: [], errorMessage: '' };
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { onCatch } = this.props;
    const tempArr = errorInfo.componentStack.split('\n').filter(Boolean);
    const regex = new RegExp('[a-zA-z]+://[^\\s]*.js', 'g');
    const errorList = tempArr.map((text) => text.replace(
      regex,
      (matchstr) => `<a href="${matchstr}" target="_blank">${matchstr}</a>`,
    ));
    this.setState({
      errorList,
      errorMessage: error.message,
    });
    onCatch(error, errorInfo);
    // 上报错误信息
    this.reportError(error);
  }

  componentWillUnmount() {
    if (this.waitTimer) clearTimeout(this.waitTimer);
  }

  // 上报错误信息
  reportError = (error) => {
    const userId = Cookies.get('userId');
    const prevErrorMessage = localStorage.getItem('errorMessage');
    const currErrorMessage = error.message;
    const log = {
      userId,
      href: window.location.href,
      errorType: 'js',
      error: JSON.stringify({ message: error.message, stack: error.stack }),
      browser: JSON.stringify(window.navigator.userAgentData),
    };
    const isEquals = prevErrorMessage === currErrorMessage;
    if (!isEquals) apiDevTrigger(log);
    localStorage.setItem('errorMessage', currErrorMessage);
  };

  handleRefresh = () => {
    window.location.reload();
  };

  handlePostFeedback = () => {
    const { errorMessage, errorList } = this.state;
    const mail = document.createElement('a');
    const params = {
      subject: 'HanFei-v2: Bug反馈',
      body: `页面${window.location.pathname}报错: ${errorMessage}\n${errorList[0]}`,
    };
    mail.href = `mailto:nebulaedata@163.com?${stringify(params)}`;
    mail.click();
    this.waitTimer = setTimeout(() => {
      mail.remove();
    }, 100);
  };

  render() {
    const { children } = this.props;
    const { hasError, errorList, errorMessage } = this.state;
    const subTitle = (
      <section style={{ marginTop: 15 }}>
        <p>网页貌似发生了一点小问题，请不要惊慌😅！</p>
        <ol style={{ display: 'inline-block', width: 300, textAlign: 'left' }}>
          <li>首先请检查网络连接是否正常；</li>
          <li>然后尝试点击【重新加载】刷新页面；</li>
          <li>如错误不能解决，点击【立即反馈】；</li>
        </ol>
      </section>
    );
    // 显示降级后的UI
    if (hasError) {
      return (
        <Result
          status="warning"
          title="网站警告"
          subTitle={subTitle}
          extra={[
            <Button
              key="console"
              type="primary"
              ghost
              icon={<SendOutlined />}
              onClick={this.handlePostFeedback}
            >
              立即反馈
            </Button>,
            <Button
              key="home"
              type="primary"
              icon={<ReloadOutlined />}
              onClick={this.handleRefresh}
            >
              重新加载
            </Button>,
          ]}
        >
          <Paragraph>
            <Text strong style={{ fontSize: 16 }}>
              错误详情：
            </Text>
          </Paragraph>
          <Collapse
            className={styles.collapse}
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              key="error"
              header={(
                <span className={styles.panelHeader}>
                  <CloseCircleFilled />
                  <span>{errorMessage}</span>
                </span>
              )}
            >
              {errorList.map((text) => (
                <Paragraph key={text} className={styles.panelBody}>
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                </Paragraph>
              ))}
            </Panel>
          </Collapse>
        </Result>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  onCatch: () => {},
};

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  onCatch: PropTypes.func,
};
