import request from '@/utils/request';

/**
 * @description 查询文档
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId 版本
 * @param {string} [params.outlineId] 大纲
 * @param {boolean} [params.origin] true/false 原始数据，不带高亮
 * @return {*}
 */
export function apiGetDocumentContent(params) {
  return request({
    method: 'get',
    url: '/worktable/getContent',
    params,
  });
}

/**
 * @description 查询大纲
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {Array}
 */
export function apiGetCatalog(params) {
  return request({
    method: 'get',
    url: '/worktable/getOutlineList',
    params,
  });
}

/**
 * @description 新增章节（同级/下级）
 * @export
 * @param {object} params
 * @param {string} params.fileUuid 文档id
 * @param {string} params.fileVersionId 版本id
 * @param {string} params.outlineId 当前章节id
 * @param {string} params.type 0：新增同级，1：新增下级
 * @param {string} params.outlineText
 * @return {*}
 */
export function apiAddChapter(params) {
  return request({
    method: 'get',
    url: '/web/file/addOutline',
    params,
  });
}

/**
 * @description 删除章节
 * @export
 * @param {object} params
 * @param {string} params.fileUuid 文档id
 * @param {string} params.fileVersionId 版本id
 * @param {string} params.outlineId 当前章节id
 * @return {*}
 */
export function apiDeleteChapter(params) {
  return request({
    method: 'get',
    url: '/web/file/delOutline',
    params,
  });
}

/**
 * @description 获取右侧看板信息
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetOverview(params) {
  return request({
    method: 'get',
    url: '/web/file/getBoardData',
    params,
  });
}

/**
 * @description 段落保存
 * @export
 * @param {object} data
 * @param {string} data.contentText
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineId
 * @return {*}
 */
export function apiContentSave(data) {
  return request({
    method: 'post',
    url: '/web/file/autoSave',
    data,
  });
}

/**
 * @description 为文档添加参数
 * @export
 * @param {object} data
 * @param {string} data.paramsUuid 参数id
 * @param {string} data.fileUuid 文档id
 * @param {string} data.fileVersionId 版本id
 * @param {string} data.outlineId 段落id
 * @return {*}
 */
export function apiAddContentParameter(data) {
  return request({
    method: 'post',
    url: '/web/file/addContentParam',
    data,
  });
}

/**
 * @description 从文档删除参数
 * @export
 * @param {object} params
 * @param {string} params.uuid
 * @return {*}
 */
export function apiRemoveContentParameter(params) {
  return request({
    method: 'get',
    url: '/web/file/delContentParam',
    params,
  });
}

/**
 * @description 查询文档参数
 * @export
 * @param {object} params
 * @param {string} [params.paramsGroupId] 参数分组id; 不传查所有
 * @param {string} [params.outlineId] 段落id; 不传查全文
 * @param {string} params.fileUuid 文档id
 * @param {string} params.fileVersionId 版本id
 * @param {string} params.orderMode 排序 all
 * @return {*}
 */
export function apiGetContentParameterList(params) {
  return request({
    method: 'get',
    url: '/web/file/getContentParam',
    params,
  });
}

/**
 * @description 提交参数清单
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.contentText
 * @param {string} data.outlineId
 * @param {string} data.actions
 * @return {*}
 */
export function apiSubmitActions(data) {
  return request({
    method: 'post',
    url: '/web/file/addAndDelContentParam',
    data,
  });
}

/**
 * @description 文档搜索
 * @export
 * @param {object} params
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.searchLike
 * @return {object}
 */
export function apiGetSearchList(params) {
  return request({
    method: 'get',
    url: '/web/file/search',
    params,
  });
}

/**
 * @description 获取可替换章节下拉列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.outlineId 章节id
 * @return {*}
 */
export function apiGetReplaceableList(params) {
  return request({
    method: 'get',
    url: '/web/file/getReplaceOutlineList',
    params,
  });
}

/**
 * @description 提交可替换的章节
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.outlineId 当前章节id
 * @param {string} params.color 标记颜色
 * @param {string} params.outlineReplaceGroupId 可替换章节组别
 * @param {Array} params.outlineList 可替换章节id
 * @return {*}
 */
export function apiSubmitReplaceable(data) {
  return request({
    method: 'post',
    url: '/web/file/confirmReplaceOutline',
    data,
  });
}

/**
 * @description 获取文件属性
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetFileAttrs(params) {
  return request({
    method: 'get',
    url: '/web/file/getFileProperty',
    params,
  });
}

/**
 * @description 获取版本记录列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetVersionList(params) {
  return request({
    method: 'get',
    url: '/web/file/getVersionList',
    params,
  });
}

/**
 * @description 获取版本详情
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} [params.fileVersionId] 不传默认最新版本信息
 * @return {*}
 */
export function apiGetVersionDetail(params) {
  return request({
    method: 'get',
    url: '/web/file/getVersionDetail',
    params,
  });
}

/**
 * @description 修改文章内参数
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.paramsUuid 必填 参数id
 * @param {string} data.paramsName 参数名
 * @param {string} data.isNecessary 是否必填
 * @param {string} data.paramsGroupId 参数分组
 * @param {string} data.paramsUseSceneId 填参角色
 * @param {string} data.paramsTypeId 参数类型
 * @param {string} data.paramsDesc 参数说明
 * @return {*}
 */
export function apiUpdateDocParameter(data) {
  return request({
    method: 'post',
    url: '/web/file/updateContentParam',
    data,
  });
}

/**
 * @description 拖动更新目录大纲
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineListMap
 * @return {*}
 */
export function apiUpdateDocCatalog(data) {
  return request({
    method: 'post',
    url: '/web/file/updateOutline',
    data,
  });
}

/**
 * @description 单独更改参数显示效果
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.outlineId
 * @param {string} params.uuid
 * @param {string} params.showText
 * @param {string} params.isUnderLine
 * @param {string} params.styleId
 * @param {string} params.unit
 * @param {string} params.remark
 * @return {*}
 */
export function apiUpdateParameterDisplay(params) {
  return request({
    method: 'get',
    url: '/web/file/updateContentParamShow',
    params,
  });
}

/**
 * @description 主文件下新增附属文件
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.fileName
 * @param {Array} data.outlineListMap 章节id
 * @return {*}
 */
export function apiAddAttachFile(data) {
  return request({
    method: 'post',
    url: '/web/file/newSubsidiaryFile',
    data,
  });
}

/**
 * @description 上传附属文件
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.fileName
 * @param {Blob} params.file
 * @return {*}
 */
export function apiUploadAttachFile(params) {
  const { file, fileName, fileUuid, fileVersionId } = params;
  const formdata = new FormData();
  formdata.append('file', file);
  formdata.append('fileName', fileName);
  formdata.append('fileUuid', fileUuid);
  formdata.append('fileVersionId', fileVersionId);
  return request({
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: '/web/file/uploadSubsidiaryFile',
    data: formdata,
  });
}

/**
 * @description 删除附属文件
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiDeleteAttachFile(params) {
  return request({
    method: 'get',
    url: '/web/file/delSubsidiaryFile',
    params,
  });
}

/**
 * @description 获取附属文件列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {Array}
 */
export function apiGetAttachFileList(params) {
  return request({
    method: 'get',
    url: '/web/file/getSubsidiaryFileList',
    params,
  });
}

/**
 * @description 获取词条下拉列表
 * @export
 * @param {*} params
 * @return {{label:string,value:string}[]}
 */
export function apiGetWordSelectableList(params) {
  return request({
    method: 'get',
    url: '/web/file/getWordsLabelValueList',
    params,
  });
}

/**
 * @description 获取词条详情
 * @export
 * @param {object} params
 * @param {string} params.wordsUuid
 * @return {object}
 */
export function apiGetWordDetail(params) {
  return request({
    method: 'get',
    url: '/web/file/getWordsInfo',
    params,
  });
}

/**
 * @description 提交标注
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.contentText
 * @param {string} data.outlineId
 * @param {string} data.actions
 * @return {*}
 */
export function apiSubmitMarkActions(data) {
  return request({
    method: 'post',
    url: '/web/file/addAndDelContentTag',
    data,
  });
}

export function apiSubmitDatabaseActions(data) {
  return request({
    method: 'post',
    url: '/web/file/addAndDelContentDb',
    data,
  });
}

/**
 * @description 获取文内数据表列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetDocDatabaseList(data) {
  return request({
    method: 'post',
    url: '/web/file/getContentDb',
    data,
  });
}

/**
 * @description 从列表中删除数据表
 * @export
 * @param {object} data
 * @param {string} data.uuid
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiDeleteDocDbTable(data) {
  return request({
    method: 'post',
    url: '/web/file/delContentDb',
    data,
  });
}

/**
 * @description 从列表更新数据表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.uuid
 * @param {string[]} data.dataSource
 * @param {string[]} data.fields
 * @param {string} data.name
 * @param {string} data.desc
 * @return {*}
 */
export function apiUpdateDocDbTable(data) {
  return request({
    method: 'post',
    url: '/web/file/updateContentDb',
    data,
  });
}

/**
 * @description 获取标注列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} [params.outlineId] 段落
 * @param {string} [params.typeId] 标注类型
 * @return {Array}
 */
export function apiGetMarkList(params) {
  return request({
    method: 'get',
    url: '/web/file/getContentTag',
    params,
  });
}

/**
 * @description 删除标注
 * @export
 * @param {object} params
 * @param {string} params.tagId
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {*}
 */
export function apiDeleteMark(params) {
  return request({
    method: 'get',
    url: '/web/file/delTag',
    params,
  });
}

/**
 * @description 更新标注
 * @export
 * @param {object} data
 * @param {string} data.tagId
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineId
 * @param {string} [data.tagContent]
 * @param {string} [data.wordsUuid]
 * @param {string} [data.lawId]
 * @return {*}
 */
export function apiUpdateMark(data) {
  return request({
    method: 'post',
    url: '/web/file/updateTag',
    data,
  });
}

/**
 * @description 提交书签
 * @export
 * @param {object} data
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.outlineId
 * @param {string} params.contentText
 * @param {Array} params.actions
 * @return {*}
 */
export function apiSubmitBookmarkActions(data) {
  return request({
    method: 'post',
    url: '/web/file/addAndDelBookmark',
    data,
  });
}

/**
 * @description
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {Array}
 */
export function apiGetBookmarkList(params) {
  return request({
    method: 'get',
    url: '/web/file/getBookmarkLabelValueList',
    params,
  });
}

/**
 * @description 删除单个参数
 * @export
 * @param {object} params
 * @param {string} params.uuid 参数唯一标识
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {*}
 */
export function apiDeleteParameterItem(params) {
  return request({
    method: 'get',
    url: '/web/file/delContentParam',
    params,
  });
}

/**
 * @description 获取书签
 * @export
 * @param {object} params
 * @param {string} params.isPaged
 * @param {string} params.pageNum
 * @param {string} params.pageSize
 * @param {string} params.paramNameLike
 * @return {*}
 */
export function apiGetBookmarkList2(params) {
  return request({
    method: 'get',
    url: '/web/file/getBookmarkList',
    params,
  });
}

/**
 * @description 删除
 * @export
 * @param {object} params
 * @param {string} params.bookmarkUuid
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @return {*}
 */
export function apiDelBookmark(params) {
  return request({
    method: 'get',
    url: '/web/file/delBookmark',
    params,
  });
}

/**
 * @description 文件类型 lv
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetFileTypeList(params) {
  return request({
    method: 'get',
    url: '/web/file/getFileTypeList',
    params,
  });
}

/**
 * @description 适用范围 lv
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetFileUseRangeList(params) {
  return request({
    method: 'get',
    url: '/web/file/getFileUseRangeList',
    params,
  });
}

/**
 * @description 文件派生
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.fileName
 * @param {string} params.fileTypeId
 * @param {string} params.fileUseRangeId
 * @param {string} params.fileDesc
 * @param {string} params.fileLabelIds
 * @return {*}
 */
export function apiFileFork(data) {
  return request({
    method: 'post',
    url: '/web/file/addDerive',
    data,
  });
}

/**
 * @description 提交审核
 * @export
 * @param {object} data
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.fileName
 * @param {string} params.fileTypeId
 * @param {string} params.fileUseRangeId
 * @param {string} params.fileDesc
 * @param {string} params.versionInfo
 * @return {*}
 */
export function apiFileApply(data) {
  return request({
    method: 'post',
    url: '/web/file/addVersion',
    data,
  });
}

/**
 * @description 修改章节题目
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.outlineId
 * @param {string} params.outlineText
 * @return {*}
 */
export function apiUpdateChapterName(params) {
  return request({
    method: 'get',
    url: '/web/file/updateOutlineName',
    params,
  });
}

/**
 * @description 修改文件名
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileName
 * @return {*}
 */
export function apiUpdateFileName(params) {
  return request({
    method: 'get',
    url: '/web/file/updateSubsidiaryFileName',
    params,
  });
}

/**
 * @description 更新文件属性
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.fileName
 * @param {string} params.fileTypeId
 * @param {string} params.fileUseRangeId
 * @param {string} params.fileDesc
 * @param {string} params.fileLabelIds
 * @return {*}
 */
export function apiUpdateFileAttributes(data) {
  return request({
    method: 'post',
    url: '/web/file/updateFileProperty',
    data,
  });
}

/**
 * @description 获取文件血缘视图
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.mode ['part', 'all']
 * @return {array}
 */
export function apiGetFileFamilyTree(params) {
  return request({
    method: 'get',
    url: '/web/file/getVersionTree',
    params,
  });
}

/**
 * @description 附属文件类型 lv
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiGetTemplateTypeList(params) {
  return request({
    method: 'get',
    url: '/web/file/getTemplateTypeDi',
    params,
  });
}

/**
 * @description 创建评标元素
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.judgmentMethodId 存在，无关评标办法可不填
 * @param {string} data.judgmentModuleId 存在，无关评标模块可不填
 * @param {string} data.judgmentDetailName
 * @param {string} data.judgmentDetailContent
 * @param {string} data.bookmarkUuid
 * @param {string} data.gradationStage
 * @param {string} data.judgmentMethodName
 * @param {string} data.judgmentModuleName
 * @param {Array} data.outlineIdList
 * @param {string} data.outlineId
 * @param {string} data.stepId
 * @param {string} data.judgmentType
 * @param {string} data.weight
 * @return {*}
 */
export function apiCreatePB(data) {
  return request({
    method: 'post',
    url: '/web/file/newJudgmentDetail',
    data,
  });
}

/**
 * @description 删除评标办法
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.judgmentMethodId
 * @return {*}
 */
export function apiDeletePBMethod(data) {
  return request({
    method: 'post',
    url: '/web/file/delJudgmentMethod',
    data,
  });
}

/**
 * @description 删除评标模块
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.judgmentModuleId
 * @return {*}
 */
export function apiDeletePBModule(data) {
  return request({
    method: 'post',
    url: '/web/file/delJudgmentModule',
    data,
  });
}

/**
 * @description 删除评标细则
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.judgmentMethodId
 * @param {string} data.judgmentModuleId
 * @param {string} data.judgmentDetailId
 * @return {*}
 */
export function apiDeletePBItem(data) {
  return request({
    method: 'post',
    url: '/web/file/delJudgmentDetail',
    data,
  });
}

/**
 * @description 获取评审元素tab树结构
 * @export
 * @param {*} params
 * @param {*} params.fileUuid
 * @param {*} params.fileVersionId
 * @return {*}
 */
export function apiGetPBTabTree(data) {
  return request({
    method: 'post',
    url: '/web/file/getJudgmentMethodModuleTree',
    data,
  });
}

/**
 * @description 获取评标细则列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 * @param {string} params.judgmentMethodId 评标办法id
 * @param {string} params.judgmentModuleId 评标模块id
 * @return {array}
 */
export function apiGetPBItemList(data) {
  return request({
    method: 'post',
    url: '/web/file/getJudgmentDetailList',
    data,
  });
}

/**
 * @description 获取评标参数列表
 * @export
 * @param {*} params
 * @param {*} params.fileUuid
 * @param {*} params.fileVersionId
 * @return {array}
 */
export function apiGetPBParameterList(data) {
  return request({
    method: 'post',
    url: '/web/file/getTendParamsList',
    data,
  });
}

/**
 * @description 获取评标方法下的章节备选项
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {array} data.outlineIdList
 * @return {array}
 */
export function apiGetPBMethodChapterList(data) {
  return request({
    method: 'post',
    url: '/web/file/getJudgmentMethodOutline',
    data,
  });
}

/**
 * @description 获取摘编
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetExcerptList(data) {
  return request({
    method: 'post',
    url: '/web/file/getLawExtractTextLists',
    data,
  });
}

/**
 * @description 修改文件属性
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.fileTypeId
 * @param {string} data.fileUseRangeId
 * @param {string} data.fileDesc
 * @param {string} data.fileLabelIds
 * @return {*}
 */
export function apiUpdateFileProperty(data) {
  return request({
    method: 'post',
    url: '/web/file/updateFileProperty',
    data,
  });
}

/**
 * @description 设置章节属性是否非必选
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineId
 * @param {string} data.isNecessary
 * @return {*}
 */
export function apiUpdateFileOutline(data) {
  return request({
    method: 'post',
    url: '/web/file/confirmNecessaryOutline',
    data,
  });
}

/**
 * @description 获取变更列表
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetDiffList(data) {
  return request({
    timeout: 25000,
    method: 'post',
    url: '/web/file/getCompareInfoList',
    data,
  });
}

/**
 * @description 提交添加对比功能的批注
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.outlineId
 * @param {string} data.annotate
 * @return {*}
 */
export function apiSubmitComment(data) {
  return request({
    method: 'post',
    url: '/web/file/addAnnotate',
    data,
  });
}

/**
 * @description 获取标记的内容，以供评标工具选择
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {Array}
 */
export function apiGetMarkContentList(data) {
  return request({
    method: 'post',
    url: '/web/file/getContentAssessTag',
    data,
  });
}

/**
 * @description 获取法律摘编列表
 * @export
 * @param {*} data
 * @param {*} data.lawId
 * @return {*}
 */
export function apiGetLawExcerptList(data) {
  return request({
    method: 'post',
    url: '/web/file/getLawContent',
    data,
  });
}

/**
 * @description 提交法律摘编
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @param {string} data.tagId
 * @param {Array} data.textitemIdList
 * @return {*}
 */
export function apiSubmitLawExcerptDisplay(data) {
  return request({
    method: 'post',
    url: '/web/file/commitLaw',
    data,
  });
}

/**
 * @description 获取法律法规更新清单
 * @export
 * @param {object} data
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiGetLawUpdateList(data) {
  return request({
    method: 'post',
    url: '/web/file/getLawChangeList',
    data,
  });
}

/**
 * @description 更改法律法规更新清单已读状态
 * @export
 * @param {object} data
 * @param {string} [data.noticeUuid] 不传更改全部状态
 * @param {string} data.fileUuid
 * @param {string} data.fileVersionId
 * @return {*}
 */
export function apiUpdateLawListReadStatus(data) {
  return request({
    method: 'post',
    url: '/web/file/readLawChange',
    data,
  });
}

/**
 * @description 上传附件
 * @export
 * @param {object} data
 * @param {string} data.file
 */
export function apiUploadAttachment(data = {}) {
  const formdata = new FormData();
  Object.keys(data).forEach((key) => {
    formdata.append(key, data[key]);
  });
  return request({
    method: 'post',
    url: '/web/file/uploadNoteAnnex',
    data: formdata,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/**
 * @description 获取摘编更新数量
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @param {string} params.fileVersionId
 */
export function apiGetLawNumber(params) {
  return request({
    method: 'get',
    url: '/web/file/getBoardSecondData',
    params,
  });
}
