/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HomeOutlined, LikeFilled, SafetyOutlined, SearchOutlined } from '@ant-design/icons';
import { List, Skeleton } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { helper } from '@/model';
import { isPowerArray } from '@/utils';
import styles from './index.module.less';

const cx = classNames.bind(styles);

const { params4searchList, searchList } = helper;

const Footer = () => (
  <p style={{ textAlign: 'left', color: '#999', marginTop: 20 }}>
    <p style={{ fontSize: 12 }}>韩非智库 • 帮助中心</p>
    <p style={{ fontSize: 13 }}>
      <SafetyOutlined style={{ marginRight: 5, fontSize: 14 }} />
      <span>搜索结果页面 • 已合规</span>
      <a
        href="mailto:nebulaedata@163.com?subject=HanFei-v2帮助中心搜索：合规反馈"
        style={{ marginLeft: 15 }}
      >
        合规反馈
      </a>
    </p>
    <p style={{ fontSize: 13 }}>
      <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
        &copy; Copyright 2022
      </span>
      <a href="http://www.nebulaedata.cn/" target="_blank" rel="noreferrer">
        南京星恒数据科技有限公司
      </a>
    </p>
  </p>
);

const defaultPageSize = 10;
const defaultPageNum = 1;

export default function HelperSearch() {
  const location = useLocation();

  const history = useHistory();

  const querySearchList = useSetRecoilState(params4searchList);
  const searchListLab = useRecoilValueLoadable(searchList);
  const loading = useMemo(() => searchListLab.state === 'loading', [searchListLab]);

  const { searchText } = location.state || {};

  const [fixed, setFixed] = useState(false);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [searchValue, setSearchValue] = useState(searchText);
  const [resultList, setResultList] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(defaultPageNum);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handleScroll = useCallback((e) => {
    setFixed(e.target.scrollTop > 0);
    setScrollWidth(e.target.offsetWidth - e.target.clientWidth);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    querySearchList({ searchContent: searchValue, pageSize, pageNum: current });
  }, [current, pageSize, querySearchList, searchValue]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSearch();
      }
    },
    [handleSearch],
  );

  const handleGoDetail = useCallback(
    (item) => {
      const { outlineId } = item;
      if (isPowerArray(outlineId)) {
        const lastOne = outlineId[outlineId.length - 1];
        if (lastOne.value) {
          history.replace(`/helper/${lastOne.value}`);
        }
      }
    },
    [history],
  );

  const handlePageChange = useCallback(
    (page, size) => {
      setCurrent(page);
      setPageSize(size);
      querySearchList({ searchContent: searchValue, pageSize: size, pageNum: page });
    },
    [querySearchList, searchValue],
  );

  const renderItem = useCallback(
    (item) => {
      const { searchResult, outlineId } = item || {};
      if (loading) {
        return (
          <List.Item>
            <Skeleton active />
          </List.Item>
        );
      }
      return (
        <List.Item className={styles.resultList}>
          <h1>
            {isPowerArray(outlineId) && outlineId.map((_) => _.label).join(' / ')}
          </h1>
          <p>
            <a
              role="link"
              tabIndex={0}
              dangerouslySetInnerHTML={{ __html: `...${searchResult}...` }}
              onClick={() => handleGoDetail(item)}
              onKeyDown={null}
            />
          </p>
        </List.Item>
      );
    },
    [handleGoDetail, loading],
  );

  useEffect(() => {
    querySearchList({
      searchContent: searchText,
      pageSize: defaultPageSize,
      pageNum: defaultPageNum,
    });
  }, [querySearchList, searchText]);

  useEffect(() => {
    const { state, contents } = searchListLab;
    if (state === 'hasValue') {
      setResultList(contents?.list || []);
      setTotal(contents?.total);
    }
  }, [searchListLab]);

  const pagination = useMemo(
    () => ({
      current,
      pageSize,
      total,
      onChange: handlePageChange,
    }),
    [current, handlePageChange, pageSize, total],
  );

  return (
    <div className={styles.HelperSearch} onScroll={handleScroll}>
      <div className={cx('search', { fixed })} style={{ right: scrollWidth }}>
        <div className={styles.logo}>
          <Link to="/helper">
            <span style={{ marginRight: 5 }}>帮助中心</span>
            <LikeFilled />
          </Link>
        </div>
        <div className={styles.inner}>
          <div className={styles.input}>
            <input
              value={searchValue}
              type="text"
              placeholder="请输入关键词为您提供帮助"
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <button type="button" onClick={handleSearch}>
              <SearchOutlined />
              <span>搜索</span>
            </button>
          </div>
          <Link to="/home" replace>
            <HomeOutlined />
            <span style={{ marginLeft: 5 }}>回到首页</span>
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.inner}>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={loading ? new Array(3).fill(0) : resultList}
            renderItem={renderItem}
            pagination={pagination}
            footer={<Footer />}
          />
        </div>
      </div>
    </div>
  );
}
