import { atom, selector } from 'recoil';
import {
  apiGetContentParameterList,
  apiGetFileBiddingInfo,
  apiGetOverview,
  apiGetParameterDetail,
  apiGetProjectFiles,
  apiGetCatalog,
  apiGetPostRecord,
  apiGetAutoInputList,
  apiGetParamsHistory,
  apiGetFillInHelperDataSource,
  apiGetAutoFillingHistoryList,
  apiGetAutoFillingRecentDS,
} from '@/service';
import { isExist, isObject, isPowerArray, isPowerObject } from '@/utils';
import { message } from 'antd';

const bdoc = {
  params4ParamsLength: atom({
    key: 'params4ParamsLength',
    value: null,
  }),
  params4BiddingInfo: atom({
    key: 'params4BiddingInfo',
    value: null,
  }),
  params4Overview: atom({
    key: 'params4Overview',
    value: null,
  }),
  params4ParameterDetail: atom({
    key: 'params4ParameterDetail',
    value: null,
  }),
  params4projectFiles: atom({
    key: 'params4projectFiles',
    value: null,
  }),
  params4FileCatalog: atom({
    key: 'params4FileCatalog',
    value: null,
  }),
  params4PostRecord: atom({
    key: 'params4PostRecord',
    value: null,
  }),
  params4AutoInputList: atom({
    key: 'params4AutoInputList',
    default: null,
  }),
  params4ParamsHistory: atom({
    key: 'params4ParamsHistory',
    default: null,
  }),
  params4AutoFillingHistory: atom({
    key: 'params4AutoFillingHistory',
    default: null,
  }),
  params4AutoFillingRecentDS: atom({
    key: 'params4AutoFillingRecentDS',
    default: null,
  }),
  paramsLength: selector({
    key: 'paramsLength',
    get: async ({ get }) => {
      const params = get(bdoc.params4ParamsLength);
      if (!params) return null;
      const { fileUuid, fileVersionId } = params;
      const { data, err } = await apiGetContentParameterList({
        fileUuid,
        fileVersionId,
      });
      if (err) {
        message.error(err.message);
        return null;
      }
      if (!Array.isArray(data)) return null;
      const completeValues = {};
      data
        .filter((item) => {
          const v = item.paramsText;
          if (isExist(v)) {
            if (Array.isArray(v) && !isPowerArray(v)) return false;
            if (isObject(v) && !isPowerObject(v)) return false;
            return true;
          }
          return false;
        })
        .forEach((item) => {
          completeValues[item.paramsUuid] = item.paramsText;
        });
      return {
        total: data.length,
        completeValues,
      };
    },
  }),
  biddingInfo: selector({
    key: 'biddingInfo',
    get: async ({ get }) => {
      const params = get(bdoc.params4BiddingInfo);
      if (!params) return null;
      const { data, err } = await apiGetFileBiddingInfo(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  overview: selector({
    key: 'docOverviewQuery',
    get: async ({ get }) => {
      const params = get(bdoc.params4Overview);
      if (!params) return null;
      const { data, err } = await apiGetOverview(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  parameterDetail: selector({
    key: 'parameterDetail',
    get: async ({ get }) => {
      const params = get(bdoc.params4ParameterDetail);
      if (!params) return null;
      const { data, err } = await apiGetParameterDetail(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  projectFiles: selector({
    key: 'projectFiles',
    get: async ({ get }) => {
      const params = get(bdoc.params4projectFiles);
      if (!params) return null;
      const { data, err } = await apiGetProjectFiles(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileCatalog: selector({
    key: 'fileCatalog',
    get: async ({ get }) => {
      const params = get(bdoc.params4FileCatalog);
      if (!params) return null;
      const { data, err } = await apiGetCatalog(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  postRecord: selector({
    key: 'postRecord',
    get: async ({ get }) => {
      const params = get(bdoc.params4PostRecord);
      if (!params) return null;
      const { data, err } = await apiGetPostRecord(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  autoInputList: selector({
    key: 'autoInputList',
    get: async ({ get }) => {
      const params = get(bdoc.params4AutoInputList);
      if (!params) return null;
      const { data, err } = await apiGetAutoInputList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  autoFillingHistoryList: selector({
    key: 'autoFillingHistoryList',
    get: async ({ get }) => {
      const params = get(bdoc.params4AutoFillingHistory);
      if (!params) return null;
      const { data, err } = await apiGetAutoFillingHistoryList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  autoFillingRecentDSList: selector({
    key: 'autoFillingRecentDSList',
    get: async ({ get }) => {
      const params = get(bdoc.params4AutoFillingRecentDS);
      if (!params) return null;
      const { data, err } = await apiGetAutoFillingRecentDS(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fillInHelperDataSource: selector({
    key: 'fillInHelperDataSource',
    get: async () => {
      const { data, err } = await apiGetFillInHelperDataSource();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  paramsHistory: selector({
    key: 'paramsHistory',
    get: async ({ get }) => {
      const params = get(bdoc.params4ParamsHistory);
      if (!params) return null;
      const { data, err } = await apiGetParamsHistory(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default bdoc;
