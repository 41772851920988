import request from '@/utils/request';

/**
 * @description 查询投标文档列表
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetTenderFileList(params) {
  return request({
    method: 'get',
    url: '/worktable/getTendDocument',
    params,
  });
}

/**
 * @description 创建投标文档
 * @export
 * @param {object} data
 * @param {object} data.docFileIndexPojoList [{"fileUuid": "文件id", "fileName": "文件名", "chapters": []
* @return {*}
 */
export function apiCreateTenderFile(data) {
  return request({
    method: 'post',
    url: '/worktable/newTendDocument',
    data,
  });
}

/**
 * @description 获取投标文件章节
 * @export
 * @param {object} params
 * @param {string} params.fileUuid
 * @return {*}
 */
export function apiGetTenderCatalog(params) {
  return request({
    method: 'get',
    url: '/worktable/getTendDocumentOutline',
    params,
  });
}

/**
 * @description 获取招标文件识别码（即招标文件fileUuid）
 * @export
 * @param {object} params
 * @param {string} params.fileUuid 投标文件uuid
 * @return {*}
 */
export function apiGetBiddingCode(params) {
  return request({
    method: 'get',
    url: '/worktable/getBidFromTend',
    params,
  });
}
