import { atom, selector } from 'recoil';
import { message } from 'antd';
import { apiGetDiffCompareResultList, apiGetDiffCompareOptions } from '@/service';

export const params4CompareOptions = atom({
  key: 'params4CompareOptions',
  default: null,
});

export const params4CompareResult = atom({
  key: 'params4CompareResult',
  default: null,
});

export const recoilCompareOptions = selector({
  key: 'compareOptions',
  get: async ({ get }) => {
    const params = get(params4CompareOptions);
    const defaultResult = [];
    if (!params) return defaultResult;
    const { data, err } = await apiGetDiffCompareOptions(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    return data || defaultResult;
  },
});

export const recoilCompareResultList = selector({
  key: 'compareResultList',
  get: async ({ get }) => {
    const defaultResult = [];
    const { params, success } = get(params4CompareResult) || {};
    if (!params) return defaultResult;
    const { data, err } = await apiGetDiffCompareResultList(params);
    if (err) {
      message.error(err.message);
      return defaultResult;
    }
    const res = data || defaultResult;
    if (success) success(res);
    return res;
  },
});
