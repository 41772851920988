import { atom, selector } from 'recoil';
import { apiGetTenderFileList, apiGetTenderCatalog, apiGetBiddingCode } from '@/service';
import { message } from 'antd';

const tdoc = {
  params4tenderList: atom({
    key: 'params4tenderList',
    value: null,
  }),
  params4chapterList: atom({
    key: 'params4chapterList',
    value: null,
  }),
  params4biddingCode: atom({
    key: 'params4biddingCode',
    value: null,
  }),
  tenderList: selector({
    key: 'tenderList',
    get: async ({ get }) => {
      const params = get(tdoc.params4tenderList);
      if (!params) return null;
      const { data, err } = await apiGetTenderFileList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  chapterList: selector({
    key: 'chapterList',
    get: async ({ get }) => {
      const params = get(tdoc.params4chapterList);
      if (!params) return null;
      const { data, err } = await apiGetTenderCatalog(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  biddingCode: selector({
    key: 'biddingCode',
    get: async ({ get }) => {
      const params = get(tdoc.params4biddingCode);
      if (!params) return null;
      const { data, err } = await apiGetBiddingCode(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data?.fileUuid;
    },
  }),
};

export default tdoc;
