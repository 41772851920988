import { atom, selector } from 'recoil';
import { message } from 'antd';
import {
  apiGetCommonTemplateList,
  apiGetOriginTemplateList,
  apiGetSearchTemplateList,
  apiGetLibSearchHotKeywords,
  apiGetLibTreeView,
} from '@/service';

const docLibraryRecoil = {
  params4LibTreeView: atom({
    key: 'params4LibTreeView',
    default: null,
  }),
  params4CommonTemplateList: atom({
    key: 'params4CommonTemplateList',
    default: null,
  }),
  params4OriginTemplateList: atom({
    key: 'params4OriginTemplateList',
    default: null,
  }),
  params4SearchTemplateList: atom({
    key: 'params4SearchTemplateList',
    default: null,
  }),
  commonTemplateList: selector({
    key: 'commonTemplateList',
    get: async ({ get }) => {
      const { params4CommonTemplateList } = docLibraryRecoil;
      const params = get(params4CommonTemplateList);
      if (!params) return null;
      const { data, err } = await apiGetCommonTemplateList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data?.list;
    },
  }),
  originTemplateList: selector({
    key: 'originTemplateList',
    get: async ({ get }) => {
      const { params4OriginTemplateList } = docLibraryRecoil;
      const params = get(params4OriginTemplateList);
      if (!params) return null;
      const { data, err } = await apiGetOriginTemplateList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data?.list;
    },
  }),
  searchTemplateList: selector({
    key: 'searchTemplateList',
    get: async ({ get }) => {
      const { params4SearchTemplateList } = docLibraryRecoil;
      const params = get(params4SearchTemplateList);
      if (!params) return null;
      const { data, err } = await apiGetSearchTemplateList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (params.isPaged === '0') {
        return data;
      }
      const { list = [], total = 0 } = data || {};
      return { list, total, ...params };
    },
  }),
  hotKeywords: selector({
    key: 'hotKeywords',
    get: async () => {
      const { data, err } = await apiGetLibSearchHotKeywords();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  libTreeView: selector({
    key: 'libTreeView',
    get: async ({ get }) => {
      const { params4LibTreeView } = docLibraryRecoil;
      const params = get(params4LibTreeView);
      if (!params) return null;
      const { data, err } = await apiGetLibTreeView(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default docLibraryRecoil;
