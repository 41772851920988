/* eslint-disable no-restricted-syntax */
import React from 'react';

export default function lazyLoad(config) {
  const components = {};
  for (const [key, value] of Object.entries(config)) {
    components[key] = React.lazy(value);
  }
  return components;
}
