import { atom, selector } from 'recoil';
import {
  apiGetRecommendDoc,
  apiGetDocCatalog,
  apiGetGuideLVList,
  apiGetGroupLabelList,
  apiGetTemplateLVList,
} from '@/service';
import { message } from 'antd';

const guideRecoil = {
  params4recommendList: atom({
    key: 'params4recommendList',
    default: null,
  }),
  params4docCatalog: atom({
    key: 'params4docCatalog',
    default: null,
  }),
  params4GroupLabelList: atom({
    key: 'params4GroupLabelList',
    default: null,
  }),
  recommendList: selector({
    key: 'recommendList',
    get: async ({ get }) => {
      const { params4recommendList } = guideRecoil;
      const params = get(params4recommendList);
      if (!params) return null;
      const { data, err } = await apiGetRecommendDoc(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docCatalog: selector({
    key: 'docCatalog',
    get: async ({ get }) => {
      const { params4docCatalog } = guideRecoil;
      const params = get(params4docCatalog);
      if (!params) return null;
      const { data, err } = await apiGetDocCatalog(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  lvList: selector({
    key: 'lvList',
    get: async () => {
      const { data, err } = await apiGetGuideLVList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  typeLV: selector({
    key: 'typeLV',
    get: ({ get }) => {
      const { biddingType } = get(guideRecoil.lvList) || {};
      return biddingType || [];
    },
  }),
  styleLV: selector({
    key: 'styleLV',
    get: ({ get }) => {
      const { biddingStyle } = get(guideRecoil.lvList) || {};
      return biddingStyle || [];
    },
  }),
  methodLV: selector({
    key: 'methodLV',
    get: ({ get }) => {
      const { judgmentMethod } = get(guideRecoil.lvList) || {};
      return judgmentMethod || [];
    },
  }),
  templateLV: selector({
    key: 'templateLV',
    get: async () => {
      const { data, err } = await apiGetTemplateLVList();
      if (err) {
        message.error(err.message);
        return [];
      }
      return data || [];
    },
  }),
  fileLV: selector({
    key: 'fileLV',
    get: async () => {
      const { data, err } = await apiGetTemplateLVList({ type: 'WJ' });
      if (err) {
        message.error(err.message);
        return [];
      }
      return data || [];
    },
  }),
  groupLabelList: selector({
    key: 'groupLabelList',
    get: async ({ get }) => {
      const params = get(guideRecoil.params4GroupLabelList);
      if (!params) return null;
      const { data, err } = await apiGetGroupLabelList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default guideRecoil;
