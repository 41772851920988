import { message } from 'antd';
import { atom, selector } from 'recoil';
import {
  apiGetBatchInfo,
  apiGetBatchUserList,
  apiGetUserList,
  apiGetBatchProjectList,
  apiGetBatchProjectInfo,
  apiGetOptions,
  apiGetBatchFileList,
  apiGetPBWorkflowList,
  apiGetPBOptions,
  apiGetPBWorkflowInfo,
  apiGetPBDetail,
} from '@/service';

export const params4BatchInfo = atom({
  key: 'params4BatchInfo',
  value: null,
});

export const params4BatchUserList = atom({
  key: 'params4BatchUserList',
  value: null,
});

export const params4BatchProjectList = atom({
  key: 'params4BatchProjectList',
  value: null,
});

export const params4BatchProjectInfo = atom({
  key: 'params4BatchProjectInfo',
  value: null,
});

export const params4BatchFilelist = atom({
  key: 'params4BatchFilelist',
  value: null,
});

export const params4PBWorkflowList = atom({
  key: 'params4PBWorkflowList',
  value: null,
});

export const params4PbOptions = atom({
  key: 'params4PbOptions',
  value: null,
});

export const params4PbWorkflowInfo = atom({
  key: 'params4PbWorkflowInfo',
  value: null,
});

export const params4PbDetail = atom({
  key: 'params4PbDetail',
  value: null,
});

export const batchInfo = selector({
  key: 'batchInfo',
  get: async ({ get }) => {
    const params = get(params4BatchInfo);
    if (!params) return null;
    const { data, err } = await apiGetBatchInfo(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const batchUserList = selector({
  key: 'batchUserList',
  get: async ({ get }) => {
    const params = get(params4BatchUserList);
    if (!params) return null;
    const { data, err } = await apiGetBatchUserList(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    let list = [];
    let total = 0;
    if (params.isPaged === '0') {
      list = data;
      total = data.length;
    } else {
      list = data.list;
      total = data.total;
    }
    return { list, total };
  },
});

export const batchFileList = selector({
  key: 'batchFileList',
  get: async ({ get }) => {
    const params = get(params4BatchFilelist);
    if (!params) return null;
    const { data, err } = await apiGetBatchFileList(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    let list = [];
    let total = 0;
    if (params.isPaged === '0') {
      list = data;
      total = data.length;
    } else {
      list = data.list;
      total = data.total;
    }
    return { list, total };
  },
});

export const batchProjectList = selector({
  key: 'batchProjectList',
  get: async ({ get }) => {
    const params = get(params4BatchProjectList);
    if (!params) return null;
    const { data, err } = await apiGetBatchProjectList(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    let list = [];
    let total = 0;
    if (params.isPaged === '0') {
      list = data;
      total = data.length;
    } else {
      list = data.list;
      total = data.total;
    }
    return { list, total };
  },
});

export const userLvList = selector({
  key: 'batchUserLvList',
  get: async () => {
    const { data, err } = await apiGetUserList();
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const buyTypeLvList = selector({
  key: 'buyTypeLvList',
  get: async () => {
    const { data, err } = await apiGetOptions({ type: 'buytype' });
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const batchProjectInfo = selector({
  key: 'batchProjectInfo',
  get: async ({ get }) => {
    const params = get(params4BatchProjectInfo);
    if (!params) return null;
    const { data, err } = await apiGetBatchProjectInfo(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const pbWorkflowInfo = selector({
  key: 'pbWorkflowInfo',
  get: async ({ get }) => {
    const params = get(params4PbWorkflowInfo);
    if (!params) return null;
    const { data, err } = await apiGetPBWorkflowInfo(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const pbDetail = selector({
  key: 'pbDetail',
  get: async ({ get }) => {
    const params = get(params4PbDetail);
    if (!params) return null;
    const { data, err } = await apiGetPBDetail(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const pbOptions = selector({
  key: 'pbOptions',
  get: async ({ get }) => {
    const params = get(params4PbOptions);
    if (!params) return null;
    const { data, err } = await apiGetPBOptions(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    return data;
  },
});

export const pbWorkflowList = selector({
  key: 'pbWorkflowList',
  get: async ({ get }) => {
    const params = get(params4PBWorkflowList);
    if (!params) return null;
    const { data, err } = await apiGetPBWorkflowList(params);
    if (err) {
      message.error(err.message);
      return null;
    }
    let list = [];
    let total = 0;
    if (params.isPaged === '0') {
      list = data;
      total = data.length;
    } else {
      list = data.list;
      total = data.total;
    }
    return { list, total };
  },
});
