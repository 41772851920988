import request from '@/utils/request';

/**
 * @description 获取帮助文档目录
 * @export
 * @param {*} data
 * @return {*}
 */
export function apiGetHelperDocCatalog(data) {
  return request({
    method: 'post',
    url: '/worktable/getHelpDocCatalogue',
    data,
  });
}

/**
 * @description 获取帮助文档章节内容
 * @export
 * @param {object} data
 * @param {string} data.helpDocId 目录
 * @return {Array}
 */
export function apiGetHelperDocContent(data) {
  return request({
    method: 'post',
    url: '/worktable/getHelpDocContent',
    data,
  });
}

/**
 * @description 查询文档
 * @export
 * @param {object} data
 * @param {string} data.searchContent 搜索内容
 * @return {Array}
 */
export function apiGetHelperSearchList(data) {
  return request({
    method: 'post',
    url: '/worktable/searchHelpDoc',
    data,
  });
}

export function apiGetHelperHotSearchList(data) {
  return request({
    method: 'post',
    url: '/worktable/getHotSearchKey',
    data,
  });
}
