import { atom, selector, selectorFamily, waitForAll } from 'recoil';
import { message } from 'antd';
import {
  apiGetDocumentContent,
  apiGetCatalog,
  apiGetOverview,
  apiGetSearchList,
  apiGetContentParameterList,
  apiGetReplaceableList,
  apiGetFileAttrs,
  apiGetAttachFileList,
  apiGetWordSelectableList,
  apiGetWordDetail,
  apiGetMarkList,
  apiGetBookmarkList,
  apiGetBookmarkList2,
  apiGetFileTypeList,
  apiGetFileUseRangeList,
  apiGetVersionList,
  apiGetVersionDetail,
  apiGetFileFamilyTree,
  apiGetTemplateTypeList,
  apiGetPBParameterList,
  apiGetPBTabTree,
  apiGetPBItemList,
  apiGetPBMethodChapterList,
  apiGetExcerptList,
  apiGetDiffList,
  apiGetMarkContentList,
  apiGetLawExcerptList,
  apiGetLawUpdateList,
  apiGetDocDatabaseList,
  apiGetLawNumber,
} from '@/service';

const ddocRecoil = {
  // params to query
  queryContent: atom({
    key: 'queryContent',
    default: null,
  }),
  queryParameters: atom({
    key: 'queryParameters',
    default: null,
  }),
  queryMarks: atom({
    key: 'queryMarks',
    default: null,
  }),
  queryCatalog: atom({
    key: 'queryCatalog',
    default: null,
  }),
  queryOverview: atom({
    key: 'queryOverview',
    default: null,
  }),
  querySearch: atom({
    key: 'querySearch',
    default: null,
  }),
  queryReplaceableList: atom({
    key: 'queryReplaceableList',
    default: null,
  }),
  queryFileAttrs: atom({
    key: 'queryFileAttrs',
    default: null,
  }),
  queryWordList: atom({
    key: 'queryWordList',
    default: null,
  }),
  queryExcerptList: atom({
    key: 'queryExcerptList',
    default: null,
  }),
  queryWordDetail: atom({
    key: 'queryWordDetail',
    default: null,
  }),
  queryBookmarkList: atom({
    key: 'queryBookmarkList',
    default: null,
  }),
  queryDatabaseList: atom({
    key: 'queryDatabaseList',
    default: null,
  }),
  queryBookmarkLV: atom({
    key: 'queryBookmarkLV', // 查询 label value 形式
    default: null,
  }),
  queryVersionDetail: atom({
    key: 'queryVersionDetail',
    default: null,
  }),
  queryFileFamilyTree: atom({
    key: 'queryFileFamilyTree',
    default: null,
  }),
  queryPBParameters: atom({
    key: 'queryPBParameters',
    default: null,
  }),
  queryPBTabTree: atom({
    key: 'queryPBTabTree',
    default: null,
  }),
  queryPBItemList: atom({
    key: 'queryPBItemList',
    default: null,
  }),
  queryPBMethodChapterList: atom({
    key: 'queryPBMethodChapterList',
    default: null,
  }),
  queryDiffList: atom({
    key: 'queryDiffList',
    default: null,
  }),
  queryMarkContentList: atom({
    key: 'queryMarkContentList',
    default: null,
  }),
  queryLawExcerptList: atom({
    key: 'queryLawExcerptList',
    default: null,
  }),
  queryLawUpdateList: atom({
    key: 'queryLawUpdateList',
    default: null,
  }),
  // query by params
  docContentQuery: selectorFamily({
    key: 'docContentQuery',
    get:
      (params) => async ({ get }) => {
        const { queryContent } = ddocRecoil;
        const _params = params || get(queryContent);
        if (!_params) return null;
        const { cb, ...restParams } = _params;
        const { data, err } = await apiGetDocumentContent(restParams);
        if (err) {
          message.error(err.message);
          return null;
        }
        const _data = data && data[0] && data[0];
        const content = _data?.contentText || ' ';
        const previous = _data?.previous;
        const next = _data?.next;
        const current = _data?.outlineId;
        const res = { content, next, previous, current };
        if (typeof cb === 'function') cb(res);
        return res;
      },
  }),
  docCatalogQuery: selectorFamily({
    key: 'docCatalogQuery',
    get:
      (params) => async ({ get }) => {
        const { queryCatalog } = ddocRecoil;
        const _params = params || get(queryCatalog);
        if (!_params) return null;
        const { data, err } = await apiGetCatalog(_params);
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  docParametersQuery: selectorFamily({
    key: 'docParametersQuery',
    get:
      (params) => async ({ get }) => {
        const { queryParameters } = ddocRecoil;
        const _params = params || get(queryParameters);
        if (!_params) return null;
        const { success, ...restParams } = _params;
        const { data, err } = await apiGetContentParameterList(restParams);
        if (err) {
          message.error(err.message);
          return null;
        }
        success?.();
        return data;
      },
  }),
  docMarksQuery: selectorFamily({
    key: 'docMarksQuery',
    get:
      (params) => async ({ get }) => {
        const { queryMarks } = ddocRecoil;
        const _params = params || get(queryMarks);
        if (!_params) return null;
        const { data, err } = await apiGetMarkList(_params);
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  docFileAttrsQuery: selectorFamily({
    key: 'docFileAttrsQuery',
    get:
      (params) => async ({ get }) => {
        const { queryFileAttrs } = ddocRecoil;
        const _params = params || get(queryFileAttrs);
        if (!_params) return null;
        const { data, err } = await apiGetFileAttrs(_params);
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  docOverviewQuery: selectorFamily({
    key: 'docOverviewQuery',
    get:
      (params) => async ({ get }) => {
        const _params = params || get(ddocRecoil.queryOverview);
        if (!_params) return null;
        const { data, err } = await apiGetOverview(_params);
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  docAttachListQuery: selectorFamily({
    key: 'docAttachListQuery',
    get: (params) => async () => {
      // 初始化之后一直不变，需要变化请引入依赖
      if (!params) return null;
      const { data, err } = await apiGetAttachFileList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docBookmarkQuery: selectorFamily({
    key: 'docBookmarkQuery',
    get:
      (arg) => async ({ get }) => {
        const { queryBookmarkList } = ddocRecoil;
        const params = arg || get(queryBookmarkList);
        if (!params) return null;
        const { data, err } = await apiGetBookmarkList2({ ...params, isPaged: '0' });
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  docDatabaseQuery: selectorFamily({
    key: 'docDatabaseQuery',
    get:
      (arg) => async ({ get }) => {
        const { queryDatabaseList } = ddocRecoil;
        const params = arg || get(queryDatabaseList);
        if (!params) return null;
        const { data, err } = await apiGetDocDatabaseList(params);
        if (err) {
          message.error(err.message);
          return null;
        }
        return data;
      },
  }),
  lawNumber: selectorFamily({
    key: 'lawNumber',
    get: (params) => async () => {
      if (!params) return null;
      const { data, err } = await apiGetLawNumber(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  typeList: selector({
    key: 'typeList',
    get: async () => {
      const { data, err } = await apiGetFileTypeList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  templateTypeList: selector({
    key: 'templateTypeList',
    get: async () => {
      const { data, err } = await apiGetTemplateTypeList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  rangeList: selector({
    key: 'rangeList',
    get: async () => {
      const { data, err } = await apiGetFileUseRangeList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  markContentList: selector({
    key: 'markContentList',
    get: async ({ get }) => {
      const { queryMarkContentList } = ddocRecoil;
      const params = get(queryMarkContentList);
      if (!params) return null;
      const { data, err } = await apiGetMarkContentList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  versionDetail: selector({
    key: 'versionDetail',
    get: async ({ get }) => {
      const { queryVersionDetail } = ddocRecoil;
      const params = get(queryVersionDetail);
      if (!params) return null;
      const { data, err } = await apiGetVersionDetail(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  fileFamilyTree: selector({
    key: 'fileFamilyTree',
    get: async ({ get }) => {
      const { queryFileFamilyTree } = ddocRecoil;
      const params = get(queryFileFamilyTree);
      if (!params) return null;
      const { data, err } = await apiGetFileFamilyTree(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  pBParameters: selector({
    key: 'pBParameters',
    get: async ({ get }) => {
      const { queryPBParameters } = ddocRecoil;
      const params = get(queryPBParameters);
      if (!params) return null;
      const { data, err } = await apiGetPBParameterList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  pBTabTree: selector({
    key: 'pBTabTree',
    get: async ({ get }) => {
      const { queryPBTabTree } = ddocRecoil;
      const params = get(queryPBTabTree);
      if (!params) return null;
      const { cb, ...rest } = params;
      const { data, err } = await apiGetPBTabTree(rest);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (typeof cb === 'function') cb(data);
      return data;
    },
  }),
  pBItemList: selector({
    key: 'pBItemList',
    get: async ({ get }) => {
      const { queryPBItemList } = ddocRecoil;
      const params = get(queryPBItemList);
      if (!params) return null;
      const { data, err } = await apiGetPBItemList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  pBMethodChapterList: selector({
    key: 'pBMethodChapterList',
    get: async ({ get }) => {
      const { queryPBMethodChapterList } = ddocRecoil;
      const params = get(queryPBMethodChapterList);
      if (!params) return null;
      const { data, err } = await apiGetPBMethodChapterList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docVersionListQuery: selectorFamily({
    key: 'versionList',
    get: (params) => async () => {
      if (!params) return null;
      const { data, err } = await apiGetVersionList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  diffList: selector({
    key: 'diffList',
    get: async ({ get }) => {
      const params = get(ddocRecoil.queryDiffList);
      if (!params) return null;
      const { data, err } = await apiGetDiffList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  wordDetail: selector({
    key: 'wordDetail',
    get: async ({ get }) => {
      const params = get(ddocRecoil.queryWordDetail);
      if (!params) return null;
      const { data, err } = await apiGetWordDetail(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docWordList: selector({
    key: 'docWordSelectableList',
    get: async ({ get }) => {
      const params = get(ddocRecoil.queryWordList);
      if (!params) return null;
      const { data, err } = await apiGetWordSelectableList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docExcerptList: selector({
    key: 'docExcerptList',
    get: async ({ get }) => {
      const params = get(ddocRecoil.queryExcerptList);
      if (!params) return null;
      const { data, err } = await apiGetExcerptList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data?.list || [];
    },
  }),
  docBookmarkList: selector({
    key: 'docBookmarkList',
    get: async ({ get }) => {
      const params = get(ddocRecoil.queryBookmarkLV);
      if (!params) return null;
      const { data, err } = await apiGetBookmarkList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  docReplaceableList: selector({
    key: 'docReplaceableList',
    get: async ({ get }) => {
      const { queryReplaceableList } = ddocRecoil;
      const params = get(queryReplaceableList);
      if (!params) return null;
      const { data, err } = await apiGetReplaceableList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  documentSearch: selector({
    key: 'documentSearch',
    get: async ({ get }) => {
      const { querySearch } = ddocRecoil;
      const params = get(querySearch);
      if (!params) return null;
      const { todo, ..._params } = params;
      const { data, err } = await apiGetSearchList(_params);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (typeof todo === 'function') todo(data);
      return data;
    },
  }),
  lawExcerptList: selector({
    key: 'lawExcerptList',
    get: async ({ get }) => {
      const { queryLawExcerptList } = ddocRecoil;
      const params = get(queryLawExcerptList);
      if (!params) return null;
      const { data, err } = await apiGetLawExcerptList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  lawUpdateList: selector({
    key: 'lawUpdateList',
    get: async ({ get }) => {
      const { queryLawUpdateList } = ddocRecoil;
      const params = get(queryLawUpdateList);
      if (!params) return null;
      const { data, err } = await apiGetLawUpdateList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // 单独查询：目录
  documentCatalog: selector({
    key: 'documentCatalog',
    get: ({ get }) => {
      const { docCatalogQuery } = ddocRecoil;
      const res = get(docCatalogQuery());
      return res;
    },
  }),
  // 单独查询：右侧栏目
  documentOverview: selector({
    key: 'documentOverview',
    get: ({ get }) => {
      const { docOverviewQuery } = ddocRecoil;
      const res = get(docOverviewQuery());
      return res;
    },
  }),
  // 单独查询：编辑器文本
  documentContent: selector({
    key: 'documentContent',
    get: ({ get }) => {
      const { docContentQuery } = ddocRecoil;
      const res = get(docContentQuery());
      return res;
    },
  }),
  // 单独查询：编辑器参数
  documentParameters: selector({
    key: 'documentParameters',
    get: ({ get }) => {
      const { docParametersQuery } = ddocRecoil;
      const res = get(docParametersQuery());
      return res;
    },
  }),
  // 单独查询：标注
  documentMarks: selector({
    key: 'documentMarks',
    get: ({ get }) => {
      const { docMarksQuery } = ddocRecoil;
      const res = get(docMarksQuery());
      return res;
    },
  }),
  // 单独查询：书签
  documentBookmarks: selector({
    key: 'documentBookmarks',
    get: ({ get }) => {
      const { docBookmarkQuery } = ddocRecoil;
      const res = get(docBookmarkQuery());
      return res;
    },
  }),
  // 单独查询：数据表
  documentDatabase: selector({
    key: 'documentDatabase',
    get: ({ get }) => {
      const { docDatabaseQuery } = ddocRecoil;
      const res = get(docDatabaseQuery());
      return res;
    },
  }),
  // initial 查询所有：合成 document 所有数据，用作初始化！
  // 注意：initial 利用 selectorFamily 的特性，因此documentAll获取只会查一次，下次使用的是缓存，需要更新数据请单独查询
  documentAllQuery: selectorFamily({
    key: 'documentAllQuery',
    get:
      (params) => ({ get }) => {
        const {
          docCatalogQuery,
          docContentQuery,
          docOverviewQuery,
          docParametersQuery,
          docFileAttrsQuery,
          docAttachListQuery,
          docMarksQuery,
          docBookmarkQuery,
          docDatabaseQuery,
        } = ddocRecoil;
        if (!params) {
          return {
            catalog: [], // 目录
            content: ' ', // 编辑器内容
            overview: {}, // 右侧栏目内容
            parameters: [], // 文档参数
            attributes: {}, // 文档属性
            attaches: [], // 文档附属文件
            marks: [], // 标注列表
            bookmarks: [], // 书签列表
          };
        }
        const { fileUuid } = params;
        const {
          catalog = [],
          main = {},
          overview = {},
          parameters = [],
          attributes = {},
          attaches = [],
          marks = [],
          bookmarks = [],
          databases = [],
        } = get(
          waitForAll({
            catalog: docCatalogQuery(params),
            main: docContentQuery(params),
            overview: docOverviewQuery(params),
            parameters: docParametersQuery(params),
            attributes: docFileAttrsQuery({ fileUuid }),
            attaches: docAttachListQuery(params),
            marks: docMarksQuery(params),
            bookmarks: docBookmarkQuery(params),
            databases: docDatabaseQuery(params),
          }),
        );
        const { content, previous, next, current } = main;
        return {
          catalog, // 目录
          content, // 编辑器内容
          overview, // 右侧栏目
          parameters, // 文档参数
          attributes, // 文档属性
          attaches, // 附属文件
          marks, // 标注列表
          bookmarks, // 书签列表
          databases, // 数据表列表
          previous, // 上一段落id
          next, // 下一段落 id
          current, // 当前段落id
        };
      },
  }),
};

export default ddocRecoil;
