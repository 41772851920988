import { atom, selector, selectorFamily } from 'recoil';
import { message } from 'antd';
import {
  apiGetParameterList,
  apiGetParameterTypeList,
  apiGetParameterSceneList,
  apiGetParameterGroupList,
  apiGetParameterStyleList,
  apiGetUnitTreeList,
  apiGetSupTableList,
  apiGetSupTableInstanceList,
  apiGetSupLabelList,
  apiGetOriginTempLv,
} from '@/service';

const informationRecoil = {
  params4parameterList: atom({
    key: 'params4parameterList',
    default: null,
  }),
  params4styleList: atom({
    key: 'params4styleList',
    default: null,
  }),
  params4ModelList: atom({
    key: 'params4ModelList',
    default: null,
  }),
  params4ModelInstanceList: atom({
    key: 'params4ModelInstanceList',
  }),
  params4LabelOptions: atom({
    key: 'params4LabelOptions',
  }),
  parameterList: selector({
    key: 'parameterList',
    get: async ({ get }) => {
      const { params4parameterList } = informationRecoil;
      const params = get(params4parameterList);
      if (!params) return null;
      const { data, err } = await apiGetParameterList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total, ...params };
    },
  }),
  parameterTypeList: selector({
    key: 'parameterTypeList',
    get: async () => {
      const { data, err } = await apiGetParameterTypeList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  parameterSceneList: selector({
    key: 'parameterSceneList',
    get: async () => {
      const { data, err } = await apiGetParameterSceneList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  // parameterSourceList: selector({
  //   key: 'parameterSourceList',
  //   get: async () => {
  //     const { data, err } = await apiGetParameterSourceList();
  //     if (err) {
  //       message.error(err.message);
  //       return null;
  //     }
  //     return data;
  //   },
  // }),
  parameterGroupList: selector({
    key: 'parameterGroupList',
    get: async () => {
      const { data, err } = await apiGetParameterGroupList();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  parameterGroupListQuery: selectorFamily({
    key: 'parameterGroupListQuery',
    get: (params) => async () => {
      const { data, err } = await apiGetParameterGroupList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  parameterStyleList: selector({
    key: 'parameterStyleList',
    get: async ({ get }) => {
      const { params4styleList } = informationRecoil;
      const params = get(params4styleList);
      if (!params) return null;
      const { cb, ...restParams } = params;
      const { data, err } = await apiGetParameterStyleList(restParams);
      if (err) {
        message.error(err.message);
        return null;
      }
      if (cb) cb(data);
      return data;
    },
  }),
  unitTreeList: selector({
    key: 'unitTreeList',
    get: async () => {
      const { data, err } = await apiGetUnitTreeList({ nodeIds: ['时间', '重量', '量词', '长度'] });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  priceUnitTreeList: selector({
    key: 'priceUnitList',
    get: async () => {
      const { data, err } = await apiGetUnitTreeList({ nodeIds: ['人民币'] });
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  modelList: selector({
    key: 'modelList',
    get: async ({ get }) => {
      const { params4ModelList } = informationRecoil;
      const params = get(params4ModelList);
      if (!params) return null;
      const { data, err } = await apiGetSupTableList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
  }),
  modelInstanceList: selector({
    key: 'modelInstanceList',
    get: async ({ get }) => {
      const { params4ModelInstanceList } = informationRecoil;
      const params = get(params4ModelInstanceList);
      if (!params) return null;
      const { data, err } = await apiGetSupTableInstanceList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      let list = [];
      let total = 0;
      if (params.isPaged === '0') {
        list = data;
        total = data.length;
      } else {
        list = data.list;
        total = data.total;
      }
      return { list, total, ...params };
    },
  }),
  labelOptions: selector({
    key: 'labelOptions',
    get: async ({ get }) => {
      const { params4LabelOptions } = informationRecoil;
      const params = get(params4LabelOptions);
      const { data, err } = await apiGetSupLabelList(params);
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
  originTempLV: selector({
    key: 'originTempLV',
    get: async () => {
      const { data, err } = await apiGetOriginTempLv();
      if (err) {
        message.error(err.message);
        return null;
      }
      return data;
    },
  }),
};

export default informationRecoil;
