import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames/bind';
import { ArrowRightOutlined, VideoCameraFilled } from '@ant-design/icons';
import Footer from '@/components/Footer';
import { apiGetUserinfo } from '@/service';
import { useSetRecoilState } from 'recoil';
import { home } from '@/model';
import { isPowerArray } from '@/utils';
import styles from './index.module.less';

const cx = classNames.bind(styles);

export default function Home() {
  const history = useHistory();

  // model
  const setUserInfo = useSetRecoilState(home.userInfo);
  const setUserMenuList = useSetRecoilState(home.userMenuList);

  // handler
  const handleStart = async () => {
    const { data, err } = await apiGetUserinfo();
    if (err) {
      history.push('/login'); // 用户未登录，跳转登录页
      return;
    }
    setUserInfo(data);
    setUserMenuList(data?.menuTree || []);
    if (isPowerArray(data?.menuTree || [])) {
      history.push(`/workbench-next/${data.menuTree[0].menuId}`); // 跳转权限第一项菜单
    }
  };

  return (
    <div className={styles.Home}>
      <div className={styles.banner}>
        <div className={styles.slogan}>
          <div className={styles.title}>招标文件自动化编制工具</div>
          {/* <div className={styles.subtitle}>国网物资公司</div> */}
          <div className={styles.toolbar}>
            <button type="button" className={cx('button', 'color')} onClick={handleStart}>
              <span className={styles.inner}>
                开始使用&nbsp;
                <ArrowRightOutlined />
              </span>
            </button>
            <Link to="/tour-video" className={styles.button}>
              <span className={styles.inner}>
                <VideoCameraFilled />
                &nbsp;视频教程
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.cardList}>
          <div className={styles.moleskineWrapper}>
            <div className={styles.moleskineNotebook}>
              <div className={styles.notebookCover}>
                <div className={styles.notebookSkin}>需求管理</div>
              </div>
              <div className={styles.notebookPage}>
                <div className={styles.thumbnail}>
                  <h1>xxx需求规格说明书</h1>
                  <p className={styles.paragraph} />
                </div>
              </div>
            </div>
            {/* <h4>需求管理</h4> */}
          </div>
          <div className={styles.moleskineWrapper}>
            <div className={styles.moleskineNotebook}>
              <div className={cx('notebookCover', 'blue')}>
                <div className={styles.notebookSkin}>招标文件</div>
              </div>
              <div className={styles.notebookPage}>
                <div className={styles.thumbnail}>
                  <h1>xxx招标书</h1>
                  <p className={styles.paragraph} />
                </div>
              </div>
            </div>
            {/* <h4>招标文件</h4> */}
          </div>
          <div className={styles.moleskineWrapper}>
            <div className={styles.moleskineNotebook}>
              <div className={cx('notebookCover', 'green')}>
                <div className={styles.notebookSkin}>技术规范书</div>
              </div>
              <div className={styles.notebookPage}>
                <div className={styles.thumbnail}>
                  <h1>xx技术规范书</h1>
                  <p className={styles.paragraph} />
                </div>
              </div>
            </div>
            {/* <h4>技术规范书</h4> */}
          </div>
          <div className={styles.moleskineWrapper}>
            <div className={styles.moleskineNotebook}>
              <div className={cx('notebookCover', 'yellow')}>
                <div className={styles.notebookSkin}>合同文件</div>
              </div>
              <div className={styles.notebookPage}>
                <div className={styles.thumbnail}>
                  <h1>xxx合同</h1>
                  <p className={styles.paragraph} />
                </div>
              </div>
            </div>
            {/* <h4>合同文档</h4> */}
          </div>
          <div className={styles.moleskineWrapper}>
            <div className={styles.moleskineNotebook}>
              <div className={cx('notebookCover', 'primary')}>
                <div className={styles.notebookSkin}>评标办法</div>
              </div>
              <div className={cx('notebookPage', 'ruled')} />
            </div>
            {/* <h4>评标办法</h4> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
