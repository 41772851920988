import './App.less';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import { RecoilRoot } from 'recoil';
import { ConfigProvider, message } from 'antd';
import moment from 'moment';
import Routers from './routers';
import ErrorBoundary from './components/ErrorBoundary';

moment.locale('zh-cn');

message.config({
  maxCount: 1,
});

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <RecoilRoot>
        <ErrorBoundary>
          <Routers />
        </ErrorBoundary>
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default App;
