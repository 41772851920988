import request from '@/utils/request';

/**
 * @description 系统登录
 * @export
 * @param {object} data
 * @param {string} data.loginId
 * @param {string} data.loginPassword
 * @return {*}
 */
export function apiLogin(data) {
  return request({
    method: 'post',
    url: '/web/user/userlogin',
    data,
  });
}

/**
 * @description 系统登出
 * @export
 * @param {*} params
 * @return {*}
 */
export function apiLogout(params) {
  return request({
    method: 'post',
    url: '/web/user/userlogout',
    params,
  });
}

/**
 * @description 获取用户信息
 * @export
 * @param {object} params
 * @param {string} params.userId
 * @return {*}
 */
export function apiGetUserinfo(params) {
  return request({
    method: 'get',
    url: '/web/user/getUserInfo',
    params,
  });
}

/**
 * @description 判断用户页面权限
 * @export
 * @param {string} menuId
 */
export function apiGetUserPermission(menuId) {
  return request({
    method: 'get',
    url: '/web/user/checkUserPermission',
    params: {
      menuId,
    },
  });
}

/**
 * @description
 * @export
 * @param {object} data
 * @param {string} data.phoneNumbers 手机号，多个手机号用英文逗号分隔
 */
export function apiSendValidateCode(data) {
  return request({
    method: 'post',
    url: '/web/user/sendValidateCode',
    data,
  });
}
