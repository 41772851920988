import request from '@/utils/request';

/**
 * @description 新建企业
 * @export
 * @param {FormData} data
 * @param {string} data.companyName 企业名称
 * @param {string} data.taxNumber 税号
 * @param {string} data.address 注册地址
 * @param {string} data.postCode 邮编
 * @param {string} data.dateEstablish 成立时间
 * @param {string} data.registeredCapital 注册资本(万元)
 * @param {string} data.legalPersonName 法人姓名
 * @param {string} data.contactPersonName 联系人姓名
 * @param {string} data.contactPersonTitle 联系人职位
 * @param {string} data.bankName 开户行名称
 * @param {string} data.phoneNumber 电话
 * @param {string} data.faxNumber 传真
 * @param {string} data.bankAccount 银行账号
 * @param {File} data.businessLicense 营业执照
 * @param {File} data.bankLicense 基本账户开户许可证
 * @param {string} data.shareholderNameList 股东名称及占股比例List
 * @param {string} data.employeesNumber 员工总人数
 * @param {string} data.projectManagerNumber 项目经理人数
 * @param {string} data.senior 高级职称人员
 * @param {string} data.intermediate 中级职称人员
 * @param {string} data.primary 初级职称人员
 * @param {string} data.fullTimePerson 专职人员
 * @param {string} data.website 网址
 * @return {*}
 */
export function apiInsertCompany(data) {
  return request({
    method: 'post',
    url: '/worktable/addCompany',
    data,
  });
}

/**
 * @description 更新企业
 * @export
 * @param {FormData} data
 * @return {*}
 */
export function apiUpdateCompany(data) {
  return request({
    method: 'post',
    url: '/worktable/updateCompany',
    data,
  });
}

/**
 * @description 获取我的企业列表
 * @export
 * @param {object} params
 * @param {string} params.isPaged 是否分页
 * @param {string} params.type all:查所有，不传查用户
 * @param {string} params.pageNum
 * @param {string} params.pageSize
 * @param {string} params.paramNameLike 模糊查询关键字
 * @return {Array}
 */
export function apiGetCompanyList(params) {
  return request({
    method: 'get',
    url: '/worktable/getCompanyList',
    params,
  });
}

/**
 * @description 删除企业
 * @export
 * @param {object} params
 * @param {string} params.companyId
 * @return {*}
 */
export function apiDeleteCompany(params) {
  return request({
    method: 'get',
    url: '/worktable/delCompany',
    params,
  });
}

/**
 * @description 退出企业
 * @export
 * @param {object} params
 * @param {string} params.companyId
 * @return {*}
 */
export function apiQuitCompany(params) {
  return request({
    method: 'get',
    url: '/worktable/quitCompany',
    params,
  });
}

/**
 * @description 更新默认企业
 * @export
 * @param {object} params
 * @param {string} params.companyId
 * @return {*}
 */
export function apiUpdateDefaultCompany(params) {
  return request({
    method: 'get',
    url: '/worktable/setDefaultCompany',
    params,
  });
}

/**
 * @description 修改密码
 * @export
 * @param {object} data
 * @param {string} data.oldPassword
 * @param {string} data.newPassword
 */
export function apiUpdatePassword(data) {
  return request({
    method: 'post',
    url: '/web/user/updatePasswd',
    data,
  });
}

/**
 * @description 更新用户信息
 * @export
 * @param {object} data
 * @param {string} data.userName
 * @param {string} data.userPhone
 * @param {string} data.avatar
 */
export function apiUpdateUserInfo(data) {
  return request({
    method: 'post',
    url: '/web/user/updateUserInfo',
    data,
  });
}
